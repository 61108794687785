import { createAsyncThunk } from '@reduxjs/toolkit'
import DataService from "../service";
import { constant } from '../constant'
import { CustomerRequest } from '@app/interfaces/interfaces';
const { path } = constant;

export const fetchCustomerList = createAsyncThunk(
    'customer/fetchCustomer',
    (arg:any, { rejectWithValue }) => DataService.get(arg,  path+'admin/customer/v1/list', rejectWithValue)
)

export const fetchBotList = createAsyncThunk(
    'bot/fetchbot',
    (arg:any, { rejectWithValue }) => DataService.get(arg,  path+'admin/customer/v1/list', rejectWithValue)
)

export const createUpdateBot = createAsyncThunk(
    'customer/createUpdateCustomer',
    (arg:CustomerRequest, { rejectWithValue }) => DataService.post(arg, path+'admin/customer/v1/add-bot-player', rejectWithValue)
)

export const createUpdateCustomer = createAsyncThunk(
    'customer/createUpdateCustomer',
    (arg:CustomerRequest, { rejectWithValue }) => DataService.post(arg, path+'admin/customer/v1', rejectWithValue)
)

export const deleteCustomer = createAsyncThunk(
    'customer/deleteCustomer',
    (arg:any, { rejectWithValue }) => DataService.delete(arg, path+`admin/customer/v1/delete/${arg?.customerId}`, rejectWithValue)
)

export const toggleActivation = createAsyncThunk(
    'customer/toggleActivation',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`admin/customer/v1/toggle-status/${arg?.customerId}`, rejectWithValue)
)

export const toggleBetActivation = createAsyncThunk(
    'customer/toggleBetActivation',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`admin/customer/v1/toggle-bet/${arg?.customerId}`, rejectWithValue)
)

export const changeCustomerPassword = createAsyncThunk(
    'customer/changeCustomerPassword',
    (arg:any, { rejectWithValue }) => DataService.post(arg, path+'admin/customer/v1/change-password', rejectWithValue)
)

export const changeCustomerMasterPassword = createAsyncThunk(
    'customer/changeCustomerMasterPassword',
    (arg:any, { rejectWithValue }) => DataService.post(arg, path+'admin/customer/v1/change-master-password', rejectWithValue)
)

export const updateCustomerFund = createAsyncThunk(
    'customer/updateCustomerFund',
    (arg:any, { rejectWithValue }) => DataService.post(arg, path+'admin/customer/v1/update-fund', rejectWithValue)
)

export const getCustomerByCustomerId = createAsyncThunk(
    'customer/getCustomerByCustomerId',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`admin/customer/v1/single/${arg?.customerId}`, rejectWithValue)
)

export const getCustomerFundByCustomerId = createAsyncThunk(
    'customer/getCustomerFundByCustomerId',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`admin/customer/v1/transactions/${arg?.customerId}`, rejectWithValue)
)

export const postGiftInfoByCustomerId = createAsyncThunk(
    'customer/postGiftInfoByCustomerId',
    (arg:any, { rejectWithValue }) => DataService.post(arg, path+`admin/account/v1/settings/add-gift/`, rejectWithValue)
)

// export const postImageUpload = createAsyncThunk(
//     'customer/postImageUpload',
//     (arg:any, { rejectWithValue }) => DataService.post(arg, path+`admin/account/v1/settings/upload-image/`, rejectWithValue)
// )