import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const AndarBhaarPage: React.FC = () => {
  return (
    <>
      <iframe src={"https://andarbahar.bhagyalaxmishivaexchange.com/?cid=BhagyaLaxmi&up=BhagyaLaxmi:BhagyaLaxmi@123"} style={{width:"100%", height:"86vh"}}></iframe>
    </>
  );
};

export default AndarBhaarPage;