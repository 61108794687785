import React, { useEffect, useState } from 'react';
import { BasicTableRow } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@app/hooks/useResponsive';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { getAgentFund} from '@app/store/actions/adminAction';
import { Table, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export const TransactionList: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    fundInfo,
    isLoader,
    error: apiError,
  } = useAppSelector((state) => state.admin);


  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: '',
    orderBy: 'createdAt',
    orderIn: -1,
  });
  const [searchText, setSearchText] = useState<string|null>('');
  const [debouncedSearchText, setDebouncedSearchText] = useState<string | null>('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { isTablet } = useResponsive();
  useEffect(() => {
    dispatch(getAgentFund(filterOption));
  }, [filterOption]);

  const filterHandler = (obj: any) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    if (debouncedSearchText || debouncedSearchText === '') {
      filterHandler({ search: debouncedSearchText, page: 1 });
    }
  }, [debouncedSearchText]);

  const handleTableChange = (pagination, filters, sorter) => {
    const orderByField = sorter?.field;
    let orderInField = null;
    if (sorter?.order === 'ascend') {
      orderInField = 1;
    } else if (sorter?.order === 'descend') {
      orderInField = -1;
    }

    filterHandler({ orderBy: orderByField, orderIn: orderInField, page: pagination.current });
    // You can access the current sorter order with sorter.order
  };

  const debounce = (func:any, timeout=300) => {
    let timer:any;
    return (...args:any) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  };
 // Update debounced search text after a delay
 const handleSearch = debounce((value:string) => {
  setDebouncedSearchText(value);
}, 300); // Adjust the delay as needed (in milliseconds)

// Handle input change
const handleSearchChange = (value:string) => {
  setSearchText(value);
  handleSearch(value);
};

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: t('common.agent-full-name'),
      dataIndex: 'agentFullName',
 
    },
  
    {
      title: t('common.amount'),
      dataIndex: 'amount',
 
    },
    {
      title: t('common.transactionType'),
      dataIndex: 'transactionType',

    },
    {
      title: t('common.remark'),
      dataIndex: 'remark',
   
    },
  
    {
      title: t('common.balance'),
      dataIndex: 'accountBalance',
    },
    {
      title: t('common.updatedAt'),
      dataIndex: 'createdAt',
      sorter: true,
      showSorterTooltip: false,
      render: (createdAt: string) => (
        <BaseRow gutter={[10, 10]}>
          <BaseCol>
            {createdAt &&
              <>
              {
                new Date(createdAt).toLocaleString('en-IN', { timeZone: 'UTC' })
               
              } 
             
              </>
              }
          </BaseCol>
        </BaseRow>
      ),
    },
    
  ];

  return (
    <>
     {
        isTablet && <Input
        placeholder="Search..."
        size={'small'}
        prefix={<SearchOutlined />}
        value={searchText}
        onChange={(e) => handleSearchChange(e.target.value)}
        style={{ marginBottom: 16,width:400}}
      />
       }
        {
        !isTablet && <Input
        placeholder="Search..."
        size={'small'}
        prefix={<SearchOutlined />}
        value={searchText}
        onChange={(e) => handleSearchChange(e.target.value)}
        style={{ marginBottom: 16}}
      />
       }
        
    {(fundInfo && fundInfo?.transactionList) &&
      <BaseTable
      columns={columns}
      dataSource={fundInfo?.transactionList ? fundInfo?.transactionList : []}
      pagination={{
        defaultCurrent: fundInfo?.pagination ? fundInfo?.pagination?.currentPage:1,
        pageSize: 10,
        total: fundInfo?.pagination ? fundInfo?.pagination.totalItems : 1,
      }}
      loading={loading}
      scroll={{ x: 800 }}
      onChange={handleTableChange}
      bordered
    />
    }
    
    </>
  );
};
