import React, { useCallback, useMemo, useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { useResetFormOnCloseModal } from './useResetFormOnCloseModal';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { Row, Col, Button } from 'antd';
import { updateCustomerFund } from "@app/store/actions/customerAction";
import { updateFund } from "@app/store/slices/adminSlice";
import { notificationController } from '@app/controllers/notificationController';
import { useAppSelector,useAppDispatch } from '@app/hooks/reduxHooks';
interface AddFundFormModalProps {
  open: boolean;
  onCancel: () => void;
  customerDetail: any;
}

export const AddFundFormModal: React.FC<AddFundFormModalProps> = ({ open, onCancel, customerDetail }) => {
  const dispatch = useAppDispatch();
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [transactionType, setTransanctionType] = useState('');

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onWithdraw = () => {
    setTransanctionType('DEBIT')
    form.submit();
  };
  const onDeposite = () => {
    setTransanctionType('CREDIT')
    form.submit();
  };
 
  const onFinish = (values: any) => {
    setLoading(true);
    const inputData ={
      customerId: customerDetail?._id,
      amount: values?.amount,
      remark: values?.remark,
      transactionType: transactionType,
    }
   
    dispatch(updateCustomerFund(inputData)).then(({payload}) => {
      if (payload) {
        if (payload?.success) {
          notificationController.success({ message: payload?.message });
          onCancel()
        }
        else {
          notificationController.error({ message: payload?.message });
        }
      }
      setLoading(false);
    })
    .catch((err:any) => {
      console.log("error", err)
      setLoading(false);
    });
  }



  return (
    <BaseModal
      title="Add Fund"
      open={open}
      onCancel={onCancel}
      footer={[
        <Button key="extra" onClick={onDeposite}>
           {t('common.deposit')} 
        </Button>,

        <Button key="submit" type="primary" onClick={onWithdraw}>
           {t('common.withdraw')} 
        </Button>,
      ]}
    >
      <BaseForm form={form} layout="vertical" name="adminFundForm" onFinish={onFinish}>
        <Row gutter={16}>
        <Col span={24}>
              <BaseForm.Item
                name="amount"
                label={t('common.amount')}
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <BaseInput type='number' placeholder={t('common.amount')} />
              </BaseForm.Item>
        </Col>
        <Col span={24}>
              <BaseForm.Item
                name="remark"
                label={t('common.remark')}
               
              >
                <BaseInput type='text' placeholder={t('common.remark')} />
              </BaseForm.Item>
        </Col>
        </Row>
      </BaseForm>
    </BaseModal>
  );
};
