import { FC, useState } from 'react';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import {  BetTimeTableList } from './BetTimeTableList';
import { AddBetTimeFormModal } from './AddBetTimeFormModal';
import { UserAddOutlined } from '@ant-design/icons';
import * as S from './AgentTable.styles';

export const  BetTimeTables: FC = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const showAddBetTimeModal = () => {
    setOpen(true);
  };
  const hideAddBetTimeModal = () => {
    setOpen(false);
  };

  return (
    <>
 
    
    <AddBetTimeFormModal open={open} onCancel={hideAddBetTimeModal}  />
    <S.TablesWrapper>
    <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent:"space-between",
          alignItems:"center",
          marginBottom:"1rem"
          
        }}
      >
        <S.TableTitle>
        { t('common.time-table') }
        </S.TableTitle>
       <BaseButton type="primary"  icon={<UserAddOutlined />} onClick={showAddBetTimeModal}>
        {t('common.add-bet-time')}
      </BaseButton>
      </div>
        <S.Card id="time-table" padding="1.25rem 1.25rem 0">
        
          < BetTimeTableList />
        </S.Card>
      </S.TablesWrapper>

    </>
  );
};
