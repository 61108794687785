import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { AdminState } from '@app/interfaces/interfaces';
import { persistUser, readUser } from '@app/services/localStorage.service';
import { 
    adminLogin, 
    getDashboard,
    getAgentFund,
    getProfile,

   } from '../actions/adminAction'

const initialState: AdminState = {
    isAdminLoggedIn: false,
    adminDetail: null,
    error: null,
    isLoader: false,
    isRecordUpdated: false,
    invalidJwtToken: false,
    dashboardData: null,
    fundInfo:null,
    isCustomerFundUpdated: false,
};


export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    checkAdmin: (state, {payload}) => {
      state.isAdminLoggedIn = payload?true:false
      state.adminDetail= JSON.parse(payload)?.user;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state, action) => {
        state.error = null;
        state.isRecordUpdated=false
      });
    builder.addCase(getProfile.fulfilled, (state, {payload}) => {
      state.error = null;
      state.isRecordUpdated=false
      state.adminDetail= payload.data;
      });
      builder.addCase(getProfile.rejected, (state, {payload}:any) => {
      state.error= payload.message;
      });


      builder.addCase(adminLogin.pending, (state, action) => {
        state.error = null;
        state.isAdminLoggedIn= false;
      });
    builder.addCase(adminLogin.fulfilled, (state, {payload}) => {
      state.error = null;
      state.isAdminLoggedIn= true;
      state.adminDetail= payload.data.user;
      });
      builder.addCase(adminLogin.rejected, (state, {payload}:any) => {
        state.adminDetail= null;
      state.isAdminLoggedIn= false;
      state.error= payload.message;
      });



      builder.addCase(getDashboard.pending, (state, action) => {
        state.error = null;
        state.isLoader = true;
      });
      builder.addCase(getDashboard.fulfilled, (state, { payload }) => {
        state.error = null;
        state.dashboardData= payload?.data;
        state.isLoader = false;
      });
  
      builder.addCase(getDashboard.rejected, (state, { payload }: any) => {
        state.error = payload?.message;
        state.isLoader = false;
       
      });


      builder.addCase(getAgentFund.pending, (state, action) => {
        state.error = null;
        state.isLoader = true;
      });
      builder.addCase(getAgentFund.fulfilled, (state, { payload }) => {
        state.error = null;
        state.fundInfo= payload?.data;
        state.isLoader = false;
      });
  
      builder.addCase(getAgentFund.rejected, (state, { payload }: any) => {
        state.error = payload.message;
        state.isLoader = false;
       
      });




      
  },
});
// Action creators are generated for each case reducer function
export const { checkAdmin,updateFund } = adminSlice.actions

export default adminSlice.reducer;
