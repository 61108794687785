import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { persistUser, readUser } from '@app/services/localStorage.service';
import {
getSettingList,
createUploadBrand,
createUpdateSliderBanner,
createUpdateMinBet,
createUpdateNumber,
deleteFile,
deleteGameNumber,
updateCloseMode,
updatePrivacyPolicy,
updateBetRules,
updateTerms,
updateRefund,
fetchTimeTableList,
createTimeTable,
updateTimeTable,
deleteBetTime
} from '../actions/settingAction';

const initialState: any = {
  settings: null,
  error: null,
  isLoader: false,
  isSettingRecordUpdated: false,
  isRecordUpdated: false,
  isTimeTableUpdated: false,
  timeTables:[]
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    agentReset: (state) => {
      state.error = null;
    },
    resetPopup: (state) => {
      state.isLoader = false;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isSettingRecordUpdated = false;
    
      state.error = null;
    },
  },
  extraReducers: (builder) => {
   
    // fetch settings //
    builder.addCase(getSettingList.pending, (state, action) => {
      state.error = null;
      state.isLoader = true;
    });
    builder.addCase(getSettingList.fulfilled, (state, { payload }) => {
      state.error = null;
      
        state.settings = payload?.data; 
        state.isLoader = false;
     
    });
    builder.addCase(getSettingList.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isLoader = false;
    });
    // end fetch agents //


        // fetch createUploadBrand //
    builder.addCase(createUploadBrand.pending, (state, action) => {
      state.error = null;
      state.isSettingRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(createUploadBrand.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isSettingRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(createUploadBrand.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isSettingRecordUpdated = false;
      state.isLoader = false;
    });


     // fetch createUpdateSliderBanner //

    builder.addCase(createUpdateSliderBanner.pending, (state, action) => {
      state.error = null;
      state.isSettingRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(createUpdateSliderBanner.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isSettingRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(createUpdateSliderBanner.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isSettingRecordUpdated = false;
      state.isLoader = false;
    });


         // fetch createUpdateMinBet //

    builder.addCase(createUpdateMinBet.pending, (state, action) => {
      state.error = null;
      state.isSettingRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(createUpdateMinBet.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isSettingRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(createUpdateMinBet.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isSettingRecordUpdated = false;
      state.isLoader = false;
    });


    // fetch createUpdateNumber //

    builder.addCase(createUpdateNumber.pending, (state, action) => {
      state.error = null;
      state.isSettingRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(createUpdateNumber.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isSettingRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(createUpdateNumber.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isSettingRecordUpdated = false;
      state.isLoader = false;
    });


   // fetch deleteFile //
    builder.addCase(deleteFile.pending, (state, action) => {
      state.error = null;
      state.isSettingRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(deleteFile.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isSettingRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(deleteFile.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isSettingRecordUpdated = false;
      state.isLoader = false;
    });


    builder.addCase(deleteGameNumber.pending, (state, action) => {
      state.error = null;
      state.isSettingRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(deleteGameNumber.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isSettingRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(deleteGameNumber.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isSettingRecordUpdated = false;
      state.isLoader = false;
    });

    builder.addCase(updateCloseMode.pending, (state, action) => {
      state.error = null;
      state.isSettingRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(updateCloseMode.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isSettingRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(updateCloseMode.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isSettingRecordUpdated = false;
      state.isLoader = false;
    });


    builder.addCase(updatePrivacyPolicy.pending, (state, action) => {
      state.error = null;
      state.isSettingRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(updatePrivacyPolicy.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isSettingRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(updatePrivacyPolicy.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isSettingRecordUpdated = false;
      state.isLoader = false;
    });


    builder.addCase(updateBetRules.pending, (state, action) => {
      state.error = null;
      state.isSettingRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(updateBetRules.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isSettingRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(updateBetRules.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isSettingRecordUpdated = false;
      state.isLoader = false;
    });


    builder.addCase(updateTerms.pending, (state, action) => {
      state.error = null;
      state.isSettingRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(updateTerms.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isSettingRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(updateTerms.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isSettingRecordUpdated = false;
      state.isLoader = false;
    });

    builder.addCase(updateRefund.pending, (state, action) => {
      state.error = null;
      state.isSettingRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(updateRefund.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isSettingRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(updateRefund.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isSettingRecordUpdated = false;
      state.isLoader = false;
    });


    builder.addCase(fetchTimeTableList.pending, (state, action) => {
      state.error = null;
      state.isTimeTableUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(fetchTimeTableList.fulfilled, (state, { payload }) => {
      state.error = null;
      state.timeTables = payload?.data; 
      state.isLoader = false;
    });
    builder.addCase(fetchTimeTableList.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isTimeTableUpdated = false;
      state.isLoader = false;
    });


    builder.addCase(createTimeTable.pending, (state, action) => {
      state.error = null;
      state.isTimeTableUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(createTimeTable.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isTimeTableUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(createTimeTable.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isTimeTableUpdated = false;
      state.isLoader = false;
    });

    
    builder.addCase(updateTimeTable.pending, (state, action) => {
      state.error = null;
      state.isTimeTableUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(updateTimeTable.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isTimeTableUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(updateTimeTable.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isTimeTableUpdated = false;
      state.isLoader = false;
    });

    builder.addCase(deleteBetTime.pending, (state, action) => {
      state.error = null;
      state.isTimeTableUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(deleteBetTime.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isTimeTableUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(deleteBetTime.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isTimeTableUpdated = false;
      state.isLoader = false;
    });

  },
});
// Action creators are generated for each case reducer function
export const {} = settingSlice.actions;

export default settingSlice.reducer;
