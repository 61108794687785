import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

import { CustomerPersonalFundInfo } from '@app/components/user/profileCard/CustomerPersonalFundInfo';

const CustomerTransactionPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('profile.nav.personalInfo.title')}</PageTitle>
      <CustomerPersonalFundInfo />
    </>
  );
};

export default CustomerTransactionPage;
