import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useResetFormOnCloseModal } from './useResetFormOnCloseModal';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Dates } from '@app/constants/Dates';
import { notificationController } from '@app/controllers/notificationController';
import { AgentRequest } from '@app/interfaces/interfaces';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { createTimeTable } from '@app/store/actions/settingAction';
import { useAppDispatch } from '@app/hooks/reduxHooks';



interface AddBetTimeFormModalProps {
  open: boolean;
  onCancel: () => void;
}

export const AddBetTimeFormModal: React.FC<AddBetTimeFormModalProps> = ({ open, onCancel }) => {
  const [isFieldsChanged, setFieldsChanged] = useState(true);
  const [editAgent, setEditAgent] = useState({
    startHour: '',
    startMinute: '',
    duration: '',
    endHour: '',
    endMinute: '',
  });
  const [isLoading, setLoading] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onFinish = useCallback(
    (values: AgentRequest) => {
      const inputData = {
        startHour: parseInt(values?.startHour),
        startMinute: parseInt(values?.startMinute),
        duration: parseInt(values?.duration),
        endHour: parseInt(values?.endHour),
        endMinute: parseInt(values?.endMinute),
      };
      setLoading(true);
      dispatch(createTimeTable(inputData))
        .then(({ payload }) => {
          if (payload) {
            if (!payload?.success) {
              notificationController.error({ message: payload?.message });
            } else {
              notificationController.success({ message: payload?.message });
              onCancel()
            }
          }
          setLoading(false);
        })
        .catch((err: any) => {
          console.log('error', err);
          // notificationController.error({ message: err.message });
          setLoading(false);
        });
    },
    [t],
  );

  const onOk = () => {
    form.submit();
  };

  return (
    <>
      <BaseModal
        title={t('common.add-new-bet-time')}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        style={{ width: '80%' }}
        footer={[]}
      >
        <BaseButtonsForm
          form={form}
          name="info"
          loading={isLoading}
          initialValues={editAgent}
          isFieldsChanged={isFieldsChanged}
          setFieldsChanged={setFieldsChanged}
          onFieldsChange={() => setFieldsChanged(true)}
          onFinish={onFinish}
        >
          <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
            <BaseCol xs={12}>
              <BaseButtonsForm.Item
                name="startHour"
                label={t('common.startHour')}
                rules={[{ required: true, message: t('common.startHour-required') }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={12}>
              <BaseButtonsForm.Item
                name="startMinute"
                label={t('common.startMinute')}
                rules={[{ required: true, message: t('common.startMinute-required') }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
          
            <BaseCol xs={12}>
              <BaseButtonsForm.Item
                name="duration"
                label={t('common.duration')}
                rules={[{ required: true, message: t('common.duration-required') }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={12}>
              <BaseButtonsForm.Item
                name="endHour"
                label={t('common.endHour')}
                rules={[{ required: true, message: t('common.endHour-required') }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={12}>
              <BaseButtonsForm.Item
                name="endMinute"
                label={t('common.endMinute')}
                rules={[{ required: true, message: t('common.endMinute-required') }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

          </BaseRow>
        </BaseButtonsForm>
      </BaseModal>
    </>
  );
};
