import React, { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { notificationController } from '@app/controllers/notificationController';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { postGiftInfoByCustomerId } from '@app/store/actions/customerAction';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';

interface GiftInfoProps {}

export const GiftInfo: React.FC<GiftInfoProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { customerId: customerId } = useParams();

  const {
    giftInfo,
    isLoader,
    pagination,
    isCustomerRecordUpdated,
    error: apiError,
  } = useAppSelector((state) => state.customer);

  const [form] = BaseForm.useForm();

  const handleFormSubmit = async (values: any) => {
    if (!values.giftTittle) {
      notificationController.error({ message: "Gift Title is required"});
      return;
    }

    if (!values.giftDescription) {
      notificationController.error({ message: "Gift Description is required"});
      return;
    }

    try {
      const payload = {...values, customerId };

      const response = await dispatch(postGiftInfoByCustomerId(payload));
      if (response.payload?.success) {
        notificationController.success({ message: payload?.message || "Gift added successfully" });
        form.resetFields();
      }
    } catch (error) {
      notificationController.error(t('common.errorOccurred'));
    }
  };

  return (
    <>
      <BaseCard>
        {true && (
          <BaseForm
            form={form}
            name="info"
            initialValues={giftInfo}
            disabled={false}
            onFinish={handleFormSubmit}
          >
            <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
              <BaseCol span={24}>
                <BaseForm.Item>
                  <BaseForm.Title>{t('common.giftInfo')}</BaseForm.Title>
                </BaseForm.Item>
              </BaseCol>

              <BaseCol xs={12}>
                <BaseForm.Item name="giftTittle" label={t('common.giftTittle')}>
                  <BaseInput />
                </BaseForm.Item>
              </BaseCol>

              <BaseCol xs={12}>
                <BaseForm.Item name="giftDescription" label={t('common.giftDescription')}>
                  <BaseInput />
                </BaseForm.Item>
              </BaseCol>

              <BaseCol span={24}>
                <BaseForm.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-save"
                    loading={isLoader}
                  >
                    {t('common.save')}
                  </Button>
                </BaseForm.Item>
              </BaseCol>
            </BaseRow>
          </BaseForm>
        )}
      </BaseCard>
    </>
  );
};