import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { MoreOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseDropdown } from '@app/components/common/BaseDropdown/Dropdown';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { fetchBetList } from '@app/store/actions/betAction';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';

export const BetTableList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    bets,
    isLoader,
    pagination,
    isRecordUpdated,
    error: apiError,
  } = useAppSelector((state) => state.bet);

  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: '',
    orderBy: 'createdAt',
    orderIn: -1,
  });
  const [searchText, setSearchText] = useState<string | null>('');
  const [debouncedSearchText, setDebouncedSearchText] = useState<string | null>('');
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<{ data: BasicTableRow[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(fetchBetList(filterOption));
  }, [filterOption, isRecordUpdated]);

  const filterHandler = (obj: any) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    if (debouncedSearchText || debouncedSearchText === '') {
      filterHandler({ search: debouncedSearchText, page: 1 });
    }
  }, [debouncedSearchText]);
  const [rowData, setRowData] = useState(null);
  const handleTableChange = (pagination, filters, sorter) => {
    const orderByField = sorter?.field;
    let orderInField = null;
    if (sorter?.order === 'ascend') {
      orderInField = 1;
    } else if (sorter?.order === 'descend') {
      orderInField = -1;
    }
    filterHandler({ orderBy: orderByField, orderIn: orderInField, page: pagination.current });
    // You can access the current sorter order with sorter.order
  };

  const debounce = (func: any, timeout = 300) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };
  const redirectDetails = () => {
    const customerId = rowData?._id;
    
    navigate(`/bets-history/${customerId}`);
  };

  const rowClicked = (record: any) => {
    console.log('row data', record);
    setRowData(record);
  };

  const positionItems = [
    {
      key: '1',
      label: t('common.view-detail'),
      onClick: () => redirectDetails(),
    },
  ];

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: t('common.startedAt'),
      dataIndex: 'startTime',
      sorter: true,
      showSorterTooltip: false,
      render: (startTime: string) => (
        <BaseRow gutter={[10, 10]}>
          <BaseCol>{startTime && <>{new Date(startTime).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</>}</BaseCol>
        </BaseRow>
      ),
    },
    {
      title: t('common.closedAt'),
      dataIndex: 'endTime',
      sorter: true,
      showSorterTooltip: false,
      render: (endTime: string) => (
        <BaseRow gutter={[10, 10]}>
          <BaseCol>{endTime && <>{new Date(endTime).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</>}</BaseCol>
        </BaseRow>
      ),
    },
   
    {
      title: t('common.close-mode'),
      dataIndex: 'closeMode',
      sorter: true,
      showSorterTooltip: false,
    },

    {
      title: t('common.win-number'),
      key: 'bet-number',
      dataIndex: 'winningNumber',
      sorter: true,
      showSorterTooltip: false,

      render: (winningNumber: any) => (
        <BaseRow gutter={[10, 10]}>
          <BaseCol>
         { winningNumber?.displayNumber? winningNumber?.displayNumber:"-"}
      
          </BaseCol>
        </BaseRow>
      ),
    },


    {
      title: t('common.status'),
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      showSorterTooltip: false,

      render: (status: string) => (
        <BaseRow gutter={[10, 10]}>
          <BaseCol>
            {status && status == 'ACTIVE' ? (
              <Status color={'green'} text={status} />
            ) : (
              <>
                {status == 'DELETED' ? <Status color={'red'} text={status} /> : <Status color={'blue'} text={status} />}
              </>
            )}
          </BaseCol>
        </BaseRow>
      ),
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '4%',
      render: (text: string, record: any) => {
        return (
          <BaseSpace>
            <BaseDropdown menu={{ items: positionItems }} trigger={['click']}>
              <BaseButton size={'small'} onClick={(e) => rowClicked(record)} style={{ marginLeft: 10 }}>
                <MoreOutlined />
              </BaseButton>
            </BaseDropdown>
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <BaseTable
      columns={columns}
      dataSource={bets ? bets : []}
      pagination={{
        defaultCurrent: pagination?.currentPage,
        pageSize: 10,
        total: pagination ? pagination.totalItems : 1,
      }}
      loading={loading}
      scroll={{ x: 800 }}
      onChange={handleTableChange}
      bordered
    />
  );
};
