import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { BettingTableList } from './BettingTableList';
import { NumberWiseBetTable } from './NumberWiseBetTable';
import { useNavigate,useParams } from 'react-router-dom';
import { getBetByBetId } from '@app/store/actions/betAction';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';

import { useTranslation } from 'react-i18next';
import * as S from './BetTable.styles';

import { BaseTabs, TabPane } from '@app/components/common/BaseTabs/BaseTabs';

// import { Counter } from '@app/components/counter';
import { NumberWiseBetHistoryTable } from './betDetails/NumberWiseBetHistoryTable';
import { BetHistoryTableList } from './betDetails/BetHistoryTableList';

export const BetHistoryTabs: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    bets,
    isLoader,
    pagination,
    isRecordUpdated,
    betById,
    error: apiError,
  } = useAppSelector((state) => state.bet);

  const { _id:betId } = useParams();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const sendWebhookRequest = async () => {
      dispatch(getBetByBetId({
        betId
      }));
    };

    // Send the initial webhook and then schedule it to run every minute
    sendWebhookRequest();
    const interval = setInterval(sendWebhookRequest, 120000);

    return () => clearInterval(interval);
  }, []);


  return (
    <>
      <S.Card>
        {betById && (
          <>
            <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
              <p style={{ marginRight: '20px', paddingRight: '20px' }}>
                Start Time:{' '}
                {betById?.startTime && (
                  <>{new Date(betById?.startTime).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</>
                )}
              </p>
              <p style={{ marginRight: '20px', paddingRight: '20px' }}>
                End Time:{' '}
                {betById?.endTime && (
                  <>{new Date(betById?.endTime).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</>
                )}
              </p>
              <p style={{ marginRight: '20px', paddingRight: '20px' }}>
                Close Mode: {betById?.closeMode && <>{betById?.closeMode}</>}
              </p>
              <p style={{ marginRight: '20px', paddingRight: '20px' }}>
                Status: {betById?.status && <>{betById?.status}</>}
              </p>
              <p style={{ marginRight: '20px', paddingRight: '20px' }}>
                Win Number: {betById?.winningNumber && <>{betById?.winningNumber?.displayNumber}</>}
              </p>
            </div>

            {/* <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
              {betById?.status === 'OPEN' && moment(betById?.endTime)?.isAfter(moment(new Date())) && (
                <Counter endTime={betById?.endTime} />
              )}
            </div> */}
          </>
        )}

        <BaseTabs defaultActiveKey="1">
          <TabPane tab={`${t('common.number-wise-bet')}`} key="1">
            <S.TablesWrapper>
              <NumberWiseBetHistoryTable />
            </S.TablesWrapper>
          </TabPane>
          <TabPane tab={`${t('common.betting-user-list')}`} key="2">
            <S.TablesWrapper>
              <BetHistoryTableList betId={betById?._id} />
            </S.TablesWrapper>
          </TabPane>
          {/* <TabPane tab={`${t('common.summary')}`} key="3">
            <S.TablesWrapper>
             <SummaryBetTable/>
             </S.TablesWrapper>
            </TabPane> */}
        </BaseTabs>
      </S.Card>
    </>
  );
};
