import React,{ useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { getDashboard } from '@app/store/actions/adminAction';
import { getSettingList } from '@app/store/actions/settingAction';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';

const MedicalDashboardPage: React.FC = () => {
  const { isDesktop } = useResponsive();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    isLoader,
 dashboardData,
    error: apiError,
  } = useAppSelector((state) => state.admin);
  useEffect(() => {
    dispatch(getDashboard());
  }, []);
  const { settings, isSettingRecordUpdated } = useAppSelector((state) => state.setting);
  useEffect(() => {
    dispatch(getSettingList());
  }, [isSettingRecordUpdated]);
  const desktopLayout = (
    <BaseRow>
      <S.LeftSideCol lg={24} xl={24} xxl={24} id="desktop-content">
        <BaseRow gutter={[60, 60]}>
          <BaseCol span={4}>
            <BaseRow gutter={[30, 30]}>
              <BaseCard>
              <p>{t('common.total-agent')}</p>
              <p>{dashboardData?.totalAgent}</p>
              </BaseCard>
       
            </BaseRow>
          </BaseCol>
          <BaseCol span={4}>
            <BaseRow gutter={[30, 30]}>
              <BaseCard>
              <p>{t('common.total-customer')}</p>
               <p>{dashboardData?.totalCustomer}</p>
              </BaseCard>
       
            </BaseRow>
          </BaseCol>
          <BaseCol span={4}>
            <BaseRow gutter={[30, 30]}>
              <BaseCard>
              <p>{t('common.today-invested')}</p>
              <p>{dashboardData?.todayInvested}</p>
              </BaseCard>
       
            </BaseRow>
          </BaseCol>
          <BaseCol span={4}>
            <BaseRow gutter={[30, 30]}>
              <BaseCard>
              <p>{t('common.today-profit')}</p>
               <p>{dashboardData?.todayProfit}</p>
              </BaseCard>
       
            </BaseRow>
          </BaseCol>
      
        </BaseRow>
    
      </S.LeftSideCol>
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <BaseRow gutter={[20, 24]}>
      <BaseCol span={12}>
      <BaseCard>
              <p>{t('common.total-agent')}</p>
              <p>{dashboardData?.totalAgent}</p>
              </BaseCard>
              
      </BaseCol>
      <BaseCol span={12}>
      <BaseCard>
              <p>{t('common.total-customer')}</p>
              <p>{dashboardData?.totalCustomer}</p>
              </BaseCard>
              
      </BaseCol>
      <BaseCol span={12}>
      <BaseCard>
              <p>{t('common.today-invested')}</p>
              <p>{dashboardData?.todayInvested}</p>
              </BaseCard>
              
      </BaseCol>
      <BaseCol span={12}>
      <BaseCard>
              <p>{t('common.today-profit')}</p>
              <p>{dashboardData?.todayProfit}</p>
              </BaseCard>
              
      </BaseCol>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default MedicalDashboardPage;
