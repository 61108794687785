import React, { useCallback, useMemo, useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { Row, Col, Button } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { useAppSelector,useAppDispatch } from '@app/hooks/reduxHooks';
import { closeTheGameByBetId } from "@app/store/actions/betAction";
import * as S from './EndGame.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { showWin, hideWin } from "@app/store/slices/betSlice";
interface DeleteConfirmationProps {
  open: boolean;
  onCancel: () => void;
  betId: string;
  gameNumber:{
    displayNumber:string,
    _id:string,
    multipleTime:string|number
  }|null
}

export const EndGameConfirmation: React.FC<DeleteConfirmationProps> = ({ open, onCancel,  betId, gameNumber }) => {
  const dispatch = useAppDispatch();
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
 

  const onOk = () => {
    setLoading(true);
    const inputData ={
      betId:betId,
      gameNumber: gameNumber   
    }

    dispatch(closeTheGameByBetId(inputData)).then(({payload}) => {
      if (payload) {
        if (!payload?.success) {
          notificationController.error({ message: payload?.message });
        }
        else {
          notificationController.success({ message: payload?.message });
          dispatch(hideWin());
          onCancel()
        }
      }
      setLoading(false);
    })
    .catch((err:any) => {
       notificationController.error({ message: err.message });
      setLoading(false);
    });
    form.submit();
  
  };


 

 

  

  return (
    <BaseModal
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
         {t('common.cancel')} 
        </Button>,
        <Button key="extra" type="primary" danger onClick={onOk}>
         {t('common.close-game')} 
        </Button>

      
      ]}
    >
      <BaseForm form={form} layout="vertical" name="endGameForm">
      <Auth.FormTitle>{t('common.close-game')}</Auth.FormTitle>
        <Row gutter={16}>
       <Col>
       <S.NumberDetail>
        {gameNumber && <>Bet number: <S.HighLighted>{gameNumber?.displayNumber}</S.HighLighted>  </>}
        </S.NumberDetail>
        <br>
        </br>
        <S.WarningText>
        {t('common.close-game-confirmation')}
        </S.WarningText>
      
          </Col>
        </Row>
      </BaseForm>
    </BaseModal>
  );
};
