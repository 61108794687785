import { FC, useState } from 'react';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { BotTableList } from './BotTableList';
import { AddCustomerFormModal } from './AddCustomerFormModal';
import { UserAddOutlined } from '@ant-design/icons';
import * as S from './CustomerTable.styles';

export const  BotTables: FC = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const showAddCustomerModal = () => {
    setOpen(true);
  };
  const hideAddCustomerModal = () => {
    setOpen(false);
  };

  return (
    <>
    <AddCustomerFormModal open={open} onCancel={hideAddCustomerModal}  />
    <S.TablesWrapper>
    <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent:"space-between",
          alignItems:"center",
          marginBottom:"1rem" 
        }}
      >
        <S.TableTitle>
        { t('common.bot-list') }
        </S.TableTitle>
       <BaseButton type="primary"  icon={<UserAddOutlined />} onClick={showAddCustomerModal}>
        {t('common.add-bot')}
      </BaseButton>
      </div>
        <S.Card id="customer-table" padding="1.25rem 1.25rem 0">
          < BotTableList />
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};
