import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

import { AgentCustomerInfo } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/AgentCustomerInfo';

const AgentCustomerPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('profile.nav.personalInfo.title')}</PageTitle>
      <AgentCustomerInfo />
    </>
  );
};

export default AgentCustomerPage;
