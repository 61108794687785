import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from '@app/hooks/useResponsive';
import { notificationController } from 'controllers/notificationController';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseDropdown } from '@app/components/common/BaseDropdown/Dropdown';
import { MoreOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { EditBetTimeFormModal } from './EditBetTimeFormModal';
import { fetchTimeTableList} from '@app/store/actions/settingAction';
import { DeleteConfirmation } from './DeleteCofirmation';

export const BetTimeTableList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    timeTables,
    isLoader,
    pagination,
    isRecordUpdated,
    isTimeTableUpdated,
    isRecordAdded,
    error: apiError,
  } = useAppSelector((state) => state.setting);



  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { isTablet } = useResponsive();
  useEffect(() => {
    dispatch(fetchTimeTableList());
  }, [isTimeTableUpdated]);




  const [rowData, setRowData] = useState(null);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);



  const openAddFundModal = () => {
    setOpen1(true);
  };
  const openAddAgentModal = () => {
    if (rowData) {
      setOpen(true);
    }
  };


  const removeAgent = () => {
    setOpen1(true);
  };

  const hideUserModal = () => {
    setRowData(null);
    setOpen1(false);
    setOpen(false);
  };
  const debounce = (func:any, timeout=300) => {
    let timer:any;
    return (...args:any) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  };

  const rowClicked = (record: any) => {
    setRowData(record);
  };

  const positionItems = [
    {
      key: '1',
      label: t('common.delete'),
      danger: true,
      onClick: () => removeAgent(),
    },
    
  ];

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: t('common.startHour'),
      dataIndex: 'startHour',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.startMinute'),
      dataIndex: 'startMinute',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.duration'),
      dataIndex: 'duration',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.endHour'),
      dataIndex: 'endHour',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.endMinute'),
      dataIndex: 'endMinute',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '4%',
      render: (text: string, record: any) => {
        return (
          <BaseSpace>
            <BaseDropdown menu={{ items: positionItems }} trigger={['click']}>
              <BaseButton size={'small'} onClick={(e) => rowClicked(record)} style={{marginLeft:10}}>
                <MoreOutlined />
              </BaseButton>
            </BaseDropdown>
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <>
       {rowData &&
       <>
      <EditBetTimeFormModal open={open} onCancel={hideUserModal} betTimeDetail={rowData} />
      <DeleteConfirmation open={open1} onCancel={hideUserModal} betTimeDetail={rowData}/>
      </>
       }
          
      <BaseTable
        columns={columns}
        dataSource={timeTables ? timeTables : []}
        loading={loading}
        scroll={{ x: 800 }}
        bordered
      />
    </>
  );
};
