import React from 'react';
import { useTranslation } from 'react-i18next';
import { BetTables } from '@app/components/bet/BetTables';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BettingTabs } from '@app/components/bet/BettingTabs';
import { BetHistoryTabs } from '@app/components/bet/BetHistoryTabs';

const BetHistoryPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle>{'Bets History'}</PageTitle>
      <BetHistoryTabs />
    </>
  );
};

export default BetHistoryPage;