import React, { useEffect, useState } from 'react';

import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

import { BaseInput } from '../../common/inputs/BaseInput/BaseInput';
import { BaseButton } from '../../common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import * as S from './ControlForm.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import { createUpdateMinBet } from '@app/store/actions/settingAction';
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const AddMinBet: React.FC = () => {
  const {
    settings,
    isLoader,
    isSettingRecordUpdated,
    error: apiError,
  } = useAppSelector((state) => state.setting);
  const [open, setOpen] = useState(false);
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [minimumBetAmount, setMinimumBetAmount] = useState(settings?.minimumBetAmount);

  const onFinish = async (values = {}) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('minimumBetAmount', minimumBetAmount);

    const response = await dispatch(createUpdateMinBet(formData));
    if (response.payload?.success) {
      notificationController.success({ message: t('common.success') });
      setLoading(false);
    } else {
      notificationController.error({ message: t('common.error') });
      setLoading(false);
    }
  };

  return (
    <>
    {
      (settings && settings?.minimumBetAmount) &&  <h1>{t('common.current-min-bet')}:- {settings?.minimumBetAmount} Rs</h1>
    }
   
      <BaseButtonsForm
        {...layout}
        name="controlForm"
        isFieldsChanged={isFieldsChanged}
        footer={
          <BaseButtonsForm.Item>
            <BaseButton htmlType="submit" type="primary" loading={isLoading}>
              {t('common.submit')}
            </BaseButton>
          </BaseButtonsForm.Item>
        }
        onFinish={onFinish}
        onFieldsChange={() => setFieldsChanged(true)}
      >
        <BaseButtonsForm.Item
          name="minimumBetAmount"
          label={t('common.update-minimumBet')}
          rules={[{ required: true, message: 'Brand Name is required' }]}
        >
          <BaseInput
            type="text"
            placeholder={t('common.update-minimumBet')}
            value={minimumBetAmount}
            onChange={(e) => setMinimumBetAmount(e.target.value)}
          />
        </BaseButtonsForm.Item>
      </BaseButtonsForm>
    </>
  );
};
