import React from 'react';

const DragonTigerPage: React.FC = () => {
  return (
    <>
      <iframe src={"https://dragontiger.bhagyalaxmishivaexchange.com/?cid=BhagyaLaxmi&up=BhagyaLaxmi:BhagyaLaxmi@123"} style={{width:"100%", height:"86vh"}}></iframe>
    </>
  );
};

export default DragonTigerPage;