import React from 'react';
import { BetTableList } from './BetTableList';

import { useTranslation } from 'react-i18next';
import * as S from './BetTable.styles';

export const BetTables: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <S.TablesWrapper>
        <S.Card id="admin-table" title={t('common.bet-list')} padding="1.25rem 1.25rem 0">
          <BetTableList />
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};