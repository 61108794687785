import { useTranslation } from 'react-i18next';
import { UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { notificationController } from '@app/controllers/notificationController';
import { createUpdateSliderBanner } from '@app/store/actions/settingAction';
import { useAppDispatch } from '@app/hooks/reduxHooks';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const SliderUploader: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [bannerDetails, setBannerDetails] = useState({
    picType: 'SLIDER',
    files: '',
  });
  const onFinish = async (values = {}) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('picType', bannerDetails.picType);
    bannerDetails.files.forEach(fileObj => {
      const file = new File([fileObj.originFileObj], fileObj.name); // Create a new File object
      formData.append('files', file);
    });
    const response = await dispatch(createUpdateSliderBanner(formData));
    if (response?.payload?.success) {
      notificationController.success({ message: response?.payload?.message });
      
    } else {
      notificationController.error({ message: response?.payload?.message });
      
    }
    setLoading(false);
  };

  return (
    <BaseButtonsForm
      {...formItemLayout}
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      name="validateForm"
      footer={
        <BaseButtonsForm.Item>
          <BaseButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.submit')}
          </BaseButton>
        </BaseButtonsForm.Item>
      }
      onFinish={onFinish}
    >
      <BaseButtonsForm.Item
        name="upload"
        label={t('forms.validationFormLabels.upload')}
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <BaseUpload
          name="files"
          action="/upload.do"
          listType="picture"
          fileList={bannerDetails.files} // Pass the fileList from state
          onChange={({ fileList }) => setBannerDetails({ ...bannerDetails, files: fileList })}
        >
          <BaseButton type="default" icon={<UploadOutlined />}>
            {t('forms.validationFormLabels.clickToUpload')}
          </BaseButton>
        </BaseUpload>
      </BaseButtonsForm.Item>
    </BaseButtonsForm>
  );
};
