import axios from "axios";
import { constant } from '../store/constant'

const { path } = constant;

class DataService {
 post(arg, endpoint, rejectWithValue){
    const authDetail = localStorage.getItem('authorization') 
    if(authDetail) {
        const token = JSON.parse(authDetail).token;
        axios.defaults.headers.common['Authorization'] = token;
    }
        return axios.post(endpoint, arg).then(({data}) => {
          
            if(endpoint === path+'admin/auth/v1/login' || endpoint === path+'client/auth/v1/login'){
                if(
                    data.code === 200
                ) {
                
                    localStorage.setItem('authorization', JSON.stringify(data?.data))
                    axios.defaults.headers.common['Authorization'] = data?.data?.token;
                }
            }
            return data
        }).catch(error=> {
            const { data} = error.response;
            if(data.code === 502 || data.code === 403){
                return rejectWithValue("Something went wrong, please refresh or try again")
            } else if(data.code === 500 && data === "jwt expired"){
                localStorage.removeItem('authorization');
                window.location.replace('/auth/login');
            }
            return rejectWithValue(error.response.data)
        })
    };

    get(arg, endpoint, rejectWithValue){
       
        const authDetail = localStorage.getItem('authorization') 
        if(authDetail) {
            const token = JSON.parse(authDetail).token;
            axios.defaults.headers.common['Authorization'] = token;
        }
        return  axios.get(endpoint, { params: arg} ).then(({data}) => {    
            return data
        }).catch(error=> {
            const { data} = error.response;
            if(data.code === 502 || data.code === 403){
                return rejectWithValue("Something went wrong, please refresh or try again")
            } 
            else if(data.code === 500 || data.code === 401 ){
               localStorage.removeItem('authorization');
                window.location.replace('/auth/login');
            }
          
            return rejectWithValue(error.response.data)
        })
    };

    delete(arg, endpoint, rejectWithValue){
        const authDetail = localStorage.getItem('authorization') 
        if(authDetail) {
            const token = JSON.parse(authDetail).token;
            axios.defaults.headers.common['Authorization'] = token;
        }
        return  axios.delete(endpoint ).then(({data}) => {
             
            return data
        }).catch(error=> {
            const { data} = error.response;
            if(data.code === 502 || data.code === 403){
                return rejectWithValue("Something went wrong, please refresh or try again")
            } else if(data.code === 500 || data.code === 401 ){
                localStorage.removeItem('authorization');
                window.location.replace('/auth/login');
            }
          
            return rejectWithValue(error.response.data)
        })
    }
}
export default new DataService();