import React, { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { notificationController } from '@app/controllers/notificationController';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { CustomerContext } from '@app/components/profile/CustomerProfileLayout';

export const CustomerPersonalInfo: React.FC = () => {

  const {
    customerPersonalInfo,
    isLoader,
    pagination,
    isCustomerRecordUpdated,
    error: apiError,
  } = useAppSelector((state) => state.customer);

  
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const isBotPlayer = customerPersonalInfo?.isBotPlayer

  console.log("info",customerPersonalInfo);

  return (
    <BaseCard>
   {
    customerPersonalInfo &&
    <BaseForm
        form={form}
        name="info"
        initialValues={customerPersonalInfo}
        disabled  
      >
        <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
          <BaseCol span={24}>
            <BaseForm.Item>
              <BaseForm.Title>{t('profile.nav.personalInfo.title')}</BaseForm.Title>
            </BaseForm.Item>
          </BaseCol>

          <BaseCol xs={12}>
            <BaseForm.Item name="fullName" label={t('common.full-name')}>
              <BaseInput />
            </BaseForm.Item>
          </BaseCol>

          <BaseCol xs={12}>
            <BaseForm.Item name="userName" label={t('common.user-name')}>
              <BaseInput />
            </BaseForm.Item>
          </BaseCol>

          <BaseCol xs={12}>
            <BaseForm.Item name="password" label={t('common.password')}>
              <BaseInput />
            </BaseForm.Item>
          </BaseCol>

          <BaseCol xs={12}>
            <BaseForm.Item name="mobile" label={t('common.mobile')}>
              <BaseInput />
            </BaseForm.Item>
          </BaseCol>
          <BaseCol xs={12}>
            <BaseForm.Item name="masterPassword" label={t('common.masterPassword')}>
              <BaseInput />
            </BaseForm.Item>
          </BaseCol>
          {!isBotPlayer && <BaseCol xs={12}>
            <BaseForm.Item name="exposerLimit" label={t('common.exposure-limit')}>
              <BaseInput />
            </BaseForm.Item>
          </BaseCol>}
          <BaseCol xs={isBotPlayer ? 24 : 12}>
            <BaseForm.Item name="accountBalance" label={t('common.accountBalance')}>
              <BaseInput />
            </BaseForm.Item>
          </BaseCol>
        </BaseRow>
      </BaseForm>

   }
      
   
      
    </BaseCard>
  );
};
