import { FC, useState } from 'react';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import {  AgentTableList } from './AgentTableList';
import { AddAgentFormModal } from './AddAgentFormModal';
import { UserAddOutlined } from '@ant-design/icons';
import * as S from './AgentTable.styles';

export const  AgentTables: FC = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const showAddAgentModal = () => {
    setOpen(true);
  };
  const hideAddAgentModal = () => {
    setOpen(false);
  };

  return (
    <>
 
    
    <AddAgentFormModal open={open} onCancel={hideAddAgentModal}  />
    <S.TablesWrapper>
    <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent:"space-between",
          alignItems:"center",
          marginBottom:"1rem"
          
        }}
      >
        <S.TableTitle>
        { t('common.agent-list') }
        </S.TableTitle>
       <BaseButton type="primary"  icon={<UserAddOutlined />} onClick={showAddAgentModal}>
        {t('common.add-agent')}
      </BaseButton>
      </div>
        <S.Card id="agent-table" padding="1.25rem 1.25rem 0">
        
          < AgentTableList />
        </S.Card>
      </S.TablesWrapper>

    </>
  );
};
