import React, { useEffect, useState, createContext  } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate,useParams } from 'react-router-dom';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { CustomerProfileInfo } from '@app/components/user/profileCard/CustomerInfo/CustomerProfileInfo';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { CustomerProfileNav } from '@app/components/user/profileCard/CustomerNav/CustomerProfileNav';
import { useResponsive } from '@app/hooks/useResponsive';
import { useAppSelector,useAppDispatch } from '@app/hooks/reduxHooks';
import { BaseRow } from '../common/BaseRow/BaseRow';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { getCustomerByCustomerId } from '@app/store/actions/customerAction';
import { getCustomerFundByCustomerId } from '@app/store/actions/customerAction';
import { BotProfileNav } from '../bot/botProflieNav';

export const CustomerContext = createContext()
const CustomerProfileLayout: React.FC = () => {
  const [customerDetails, setCustomerDetails] = useState();

  const { customerId } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCustomerByCustomerId({ customerId })).then(({ payload }) => {
      if (payload?.success) {
        setCustomerDetails(payload.data);
      }
    });

  }, [customerId]);


  const { t } = useTranslation();
  const { isTablet: isTabletOrHigher, mobileOnly } = useResponsive();
  const location = useLocation();
  const navigate = useNavigate();

  const { isTablet } = useResponsive();

  const isTitleShown = isTabletOrHigher || (mobileOnly && location.pathname === '/users');
  const isMenuShown = isTabletOrHigher || (mobileOnly && location.pathname !== '/users');
  const isBotPlayer = customerDetails?.isBotPlayer

  useEffect(() => {
    isTablet && location.pathname === '/customer' && navigate('customer-info');
  }, [isTablet, location.pathname, navigate]);

  return (
    <CustomerContext.Provider value={customerDetails}>
      <PageTitle>{t('profile.title')}</PageTitle>
      {!isTitleShown && (
       <div style={{display:'flex',flexWrap:'wrap'}}>
       <Btn  type="text" onClick={() => navigate(`/customer/customer-info/${customerId}`)}>
      {t('profile.nav.personalInfo.title')}
    </Btn>
    <Btn  type="text" onClick={() => navigate(`/customer/customer-fund-info/${customerId}`)}>
      {t('common.transactionInfo')}
    </Btn>
    <Btn  type="text" onClick={() => navigate(`/customer/customer-gif-info/${customerId}`)}>
      {t('common.giftInfo')}
    </Btn>
    </div>
      )}

      <BaseRow gutter={[30, 30]}>
        {isTitleShown && (
          <BaseCol xs={24} md={24} xl={8}>
            <ProfileCard>
              <BaseRow gutter={[30, 30]}>
                <BaseCol xs={24} md={12} xl={24}>
                  <CustomerProfileInfo />
                </BaseCol>
                <BaseCol xs={24} md={12} xl={24}>
                {isBotPlayer ? <BotProfileNav/>  : <CustomerProfileNav/>}
                </BaseCol>
              </BaseRow>
            </ProfileCard>
          </BaseCol>
        )}

        {isMenuShown && (
          <BaseCol xs={24} md={24} xl={16}>
            <Outlet />
          </BaseCol>
        )}
      </BaseRow>
    </CustomerContext.Provider>
  );
};

const ProfileCard = styled(BaseCard)`
  height: unset;
`;

const Btn = styled(BaseButton)`
  font-size: .8rem;
  margin: .5rem;
  font-weight: 600;
  padding: 4px;
  height: unset;
 
  background:var(--background-color);
`;


export default CustomerProfileLayout;
