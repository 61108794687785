import React,{ useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentTables } from '@app/components/payment/PaymentTables';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { getSettingList } from '@app/store/actions/settingAction';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';

const PaymentPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { settings, isSettingRecordUpdated } = useAppSelector((state) => state.setting);
  useEffect(() => {
    if(!settings){

      dispatch(getSettingList());
    }
  }, [isSettingRecordUpdated]);
  return (
    <>
      <PageTitle>Payments</PageTitle>
      <PaymentTables />
    </>
  );
};

export default PaymentPage;