import { useEffect, useState } from 'react';
import { calculateTimeLeft } from '@app/utils/utils';
import { showWin, hideWin } from "@app/store/slices/betSlice";
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';

import './styles.css';

export const Counter = ({endTime= new Date()}) => {
  const dispatch = useAppDispatch();
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft({endTime}));

  useEffect(() => {
    setTimeout(() => setTimeLeft(calculateTimeLeft({endTime})), 1000);
    if(timeLeft.days==0){
      if(timeLeft.hours==0){
        if(timeLeft.minutes==0){
          if(timeLeft.seconds!=0){
            dispatch(showWin());
          }
          else{
            dispatch(hideWin());
          }
         
        }
        else{
          dispatch(hideWin());
        }
       
      }
    }
  }, [timeLeft]);

  return (

    <div className="counter">
      { timeLeft.seconds != undefined && <>
        {timeLeft.days >0 && 
      <div className="counter-item">
        <span className="value">{String(timeLeft.days).padStart(2, '0')}</span>
        <span className="label">Days</span>
      </div>

      }
      
{
  timeLeft.hours >0 &&
  <div className="counter-item">
  <span className="value">{String(timeLeft.hours).padStart(2, '0')}</span>
  <span className="label">Hours</span>
</div>
}
     

      <div className="counter-item">
        <span className="value">
          {String(timeLeft.minutes).padStart(2, '0')}
        </span>
        <span className="label">Minutes</span>
      </div>

      <div className="counter-item">
        <span className="value">
          {String(timeLeft.seconds).padStart(2, '0')}
        </span>
        <span className="label">Seconds</span>
      </div>
      </>

      }
      
    </div>
  );
};