import { NumericLiteral } from 'typescript';

export type Dimension = number | string;

export type ChartData = number[];

export type xData = number[] | string[];

export type LanguageType = 'in' | 'en';

export type ThemeType = 'light' | 'dark';

export interface ChartSeries {
  seriesName: string;
  value: number;
  data: {
    day: number;
    value: NumericLiteral;
  };
  name: string;
}

export type ChartSeriesData = ChartSeries[];

export type Severity = 'success' | 'error' | 'info' | 'warning';

export type TwoFactorAuthOption = 'email' | 'phone';

export type ActivityStatusType = 'sold' | 'booked' | 'added';

export enum CurrencyTypeEnum {
  INR ='INR',
  USD = 'USD',
  ETH = 'ETH',
  BTC = 'BTC',
}

export interface PaymentCard {
  cvc: string;
  expiry: string;
  name: string;
  number: string;
  // eslint-disable-next-line
  focused: any;
  background: string;
  isEdit: boolean;
}


export interface AgentRequest {
  _id?:string;
  agentId?:string;
  userName: string;
  fullName: string;
  mobile: string;
  exposerLimit: number,
  password?:string;
  masterPassword?:string;

}
export interface CustomerRequest {
  _id?:string;
  customerId?:string;
  userName: string;
  fullName: string;
  mobile: string;
  exposerLimit: number,
  password?:string;
  masterPassword?:string;

}
export interface SettingRequest {
  _id?:string;
  brandName?:string;
  logo: string;
  picType: string;
  files: string;
  minimumBetAmount: number,
  displayNumber?:number;
  multipleTime?:number;

}

export interface AgentChangePassword {
  agentId:string;
  newPassword: string;
  masterPassword: string;
}
export interface AdminChangePassword {
  
  newPassword: string;
  masterPassword: string;
  confirmPassword:string;
}
export interface AgentFundUpdate {
  agentId?:string;
  amount: number;
  transactionType?: string;
  remark: string;
}

export interface LoginRequest {
  userName: string;
  password: string;
}

export interface Pagination {
  totalItems?:number;
  currentCount?:number;
  itemsPerPage?:number;
  currentPage?:number;
  hasNextPage?:boolean,
  hasPreviousPage?:boolean,
  totalPages?:number
}

export interface UserModel {
  fullName: string;
  mobile: string;
  id: number | string;
  _id: number | string;
  userName: string;
  email: string;
}


export interface AgentModel {
  fullName: string;
  mobile: string;
  id: number | string;
  _id: number | string;
  userName: string;
  email: string;
}

export interface AdminState {
  isAdminLoggedIn: boolean,
  adminDetail: UserModel | null;
  error: string|null,
  isLoader: boolean,
  fundInfo: AgentFund | null,
  isRecordUpdated: boolean,
  invalidJwtToken: boolean,
  dashboardData: {totalAgent:number, totalCustomer:number, todayInvested:number,todayProfit:number} | null;
}

export interface AgentFundDetail {
  accountBalance: number;
  agentId: number | string;
  agent:string;
  remark:string;
  transactionType:string;
  createdAt:string|number;
  updatedAt:string|number;

}
export interface AgentFund {
  transactionList: [AgentFundDetail];
  pagination: Pagination;

}


export interface AgentState {
  agents:[AgentModel]| null,
  agentPersonalInfo: AgentModel | null,
  agentFundInfo: AgentFund | null,
  error: string|null,
  isLoader: boolean,
  sortBy: string|null,
  isRecordAdded: boolean,
  pagination:null|Pagination,
  isRecordUpdated: boolean,
  isAgentRecordUpdated: boolean,
  isAgentFundUpdated: boolean,
}

export interface SettingState {
  settings:null,
  settingsSliderInfo:  null,
  settingBannerInfo:  null,
  error: string|null,
  isLoader: boolean,
  sortBy: string|null,
  isRecordAdded: boolean,
  pagination:null|Pagination,
  isRecordUpdated: boolean,
  isSettingRecordUpdated: boolean,
  
}