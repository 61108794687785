import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { AgentCustomerList } from '@app/components/agent/AgentCustomerList';
import { BaseFormTitle } from '@app/components/common/forms/components/BaseFormTitle/BaseFormTitle';

export const AgentCustomerInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BaseCard>
    <BaseRow>
    <BaseCol>
    <BaseFormTitle style={{marginBottom:'10px'}}>
    {t('common.agentCustomerInfo')}
    </BaseFormTitle>
    </BaseCol>
    </BaseRow>
      <AgentCustomerList/>
    </BaseCard>
  );
};
