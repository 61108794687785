import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

import { BaseButton } from '../common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import { updateTerms } from '@app/store/actions/settingAction';
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const AddTerm: React.FC = () => {
  const {
    settings,
    isLoader,
    isSettingRecordUpdated,
    error: apiError,
  } = useAppSelector((state) => state.setting);
  const [open, setOpen] = useState(false);
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [content, setContent] = useState('');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    setContent(settings?.term)
  }, [settings?.term]);

  const onFinish = async (values = {}) => {
    setLoading(true);
    const response = await dispatch(updateTerms({content:content}));
    if (response.payload?.success) {
      notificationController.success({ message: t('common.success') });
      setLoading(false);
    } else {
      notificationController.error({ message: t('common.error') });
      setLoading(false);
    }
  };

  return (
    <>
      <BaseButtonsForm
        {...layout}
        name="controlForm"
        isFieldsChanged={isFieldsChanged}
        onFinish={onFinish}
        onFieldsChange={() => setFieldsChanged(true)}
        footer={<></>}
      >
        <div
          style={{ border: '1px solid grey', padding: '10px', marginBottom: '20px' }}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
        {content && <ReactQuill theme="snow" value={content} onChange={setContent} />}

        <BaseButton style={{ marginTop: '20px' }} htmlType="submit" type="primary" loading={isLoading}>
          {t('common.save')}
        </BaseButton>
      </BaseButtonsForm>
    </>
  );
};
