import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';

import moment from 'moment';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { notificationController } from 'controllers/notificationController';

import { fetchNumberWiseBetting } from '@app/store/actions/betAction';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { EndGameConfirmation } from '../EndGameCofirmation';

interface GameNumberType {
  displayNumber: string;
  _id: string;
  multipleTime: string | number;
}

export const NumberWiseBetHistoryTable: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    bets,
    isLoader,
    pagination,
    isRecordUpdated,
    betById,
    error: apiError,
  } = useAppSelector((state) => state.bet);

  const [open, setOpen] = useState(false);
  const [gameNumber, setGameNumber] = useState<GameNumberType | null>(null);

  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: '',
    orderBy: 'createdAt',
    orderIn: -1,
  });
  const [searchText, setSearchText] = useState<string | null>('');
  const [debouncedSearchText, setDebouncedSearchText] = useState<string | null>('');
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  // const [tableData, setTableData] = useState<{ data: BasicTableRow[]; loading: boolean }>({
  //   data: [],
  //   loading: false,
  // });
  const { t } = useTranslation();
  useEffect(() => {
    if (betById?._id) {
      dispatch(fetchNumberWiseBetting({ betId: betById._id }))
        .then(({ payload }) => {
          if (payload) {
            if (payload?.success) {
              setTableData(payload?.data);
            } else {
              notificationController.error({ message: payload?.message });
            }
          }
          setLoading(false);
        })
        .catch((err: any) => {
          console.log('error', err);
          //  notificationController.error({ message: err.message });
          setLoading(false);
        });
    }
  }, [betById?._id, filterOption]);

  const filterHandler = (obj: any) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    if (debouncedSearchText || debouncedSearchText === '') {
      filterHandler({ search: debouncedSearchText, page: 1 });
    }
  }, [debouncedSearchText]);
  const [rowData, setRowData] = useState(null);
  const handleTableChange = (pagination, filters, sorter) => {
    const orderByField = sorter?.field;
    let orderInField = null;
    if (sorter?.order === 'ascend') {
      orderInField = 1;
    } else if (sorter?.order === 'descend') {
      orderInField = -1;
    }

    filterHandler({ orderBy: orderByField, orderIn: orderInField, page: pagination.current });
    // You can access the current sorter order with sorter.order
  };

  const debounce = (func: any, timeout = 300) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };
  const redirectDetails = () => {
    const customerId = rowData?._id;

    navigate(`/customer/customer-info/${customerId}`);
  };

  const rowClicked = (record: any) => {
    const num = record?.displayNumber;
    const objArr = betById?.gameNumbers;
    const index = objArr.findIndex((x) => x?.displayNumber == num);
    setGameNumber(betById?.gameNumbers[index]);
    setRowData(record);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: t('common.bet-number'),
      dataIndex: 'displayNumber',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.invested-amount'),
      dataIndex: 'sumInvestedAmount',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.total-amount'),
      dataIndex: 'sumTotalAmount',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.profit'),
      dataIndex: 'profit',
      render: (profit: number, record: any) => {
         const max = Math.max.apply(null, tableData.map(item => item.profit));
       
        return (  <>
      
          {
            profit &&  profit==max ? <span className="blink">{profit}</span>:<>{profit<0? <span className="loss">{profit}</span>:<span>{profit}</span>}</>
          } 
                   
        
        </>
    
        );
      },
    },
  ];


 
  return (
    <>
    

      <BaseTable
        columns={ columns}
        dataSource={tableData}
       
        loading={loading}
        scroll={{ x: 800 }}
        onChange={handleTableChange}
        bordered
      />
    </>
  );
};
