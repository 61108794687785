import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageTitle } from '@app/components/common/PageTitle/PageTitle';


import { CustomerPersonalInfo } from '@app/components/user/profileCard/CustomerPersonalInfo';

const CustomerPersonalInfoPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('profile.nav.personalInfo.title')}</PageTitle>
      <CustomerPersonalInfo />
    </>
  );
};

export default CustomerPersonalInfoPage;
