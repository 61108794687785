import React, { useEffect, useState } from 'react';
import { BasicTableRow } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import {  Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useResponsive } from '@app/hooks/useResponsive';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/Status/Status';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseDropdown } from '@app/components/common/BaseDropdown/Dropdown';
import { MoreOutlined } from '@ant-design/icons';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { EditCustomerFormModal } from './EditCustomerFormModal';
import { AddFundFormModal } from './AddFundFormModal';
import { fetchBotList, toggleActivation } from '@app/store/actions/customerAction';
import { setCurrentCustomer } from '@app/store/slices/customerSlice';

export const BotTableList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    customers,
    pagination,
    isRecordUpdated,
    error: apiError,
  } = useAppSelector((state) => state.customer);

  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: '',
    orderBy: 'createdAt',
    orderIn: -1,
    isBotPlayer:true,
  });
  const [searchText, setSearchText] = useState<string|null>('');
  const [debouncedSearchText, setDebouncedSearchText] = useState<string|null>('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { isTablet } = useResponsive();

  useEffect(() => {
    dispatch(fetchBotList(filterOption));
  }, [filterOption, isRecordUpdated]);

  const filterHandler = (obj: any) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    if (debouncedSearchText || debouncedSearchText === '') {
      filterHandler({ search: debouncedSearchText, page: 1 });
    }
  }, [debouncedSearchText]);

  const [rowData, setRowData] = useState(null);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const openAddFundModal = () => {
    setOpen1(true);
  };
  const openAddCustomerModal = () => {
    if (rowData) {
      setOpen(true);
    }
  };
  const openAddPasswordModal = () => {
    setOpen2(true);
  };
  const openAddMasterPasswordModal =()=>{
    setOpen4(true);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const orderByField = sorter?.field;
    let orderInField = null;
    if(sorter?.order ==='ascend'){
      orderInField=1
    }
    else if(sorter?.order ==='descend'){
      orderInField=-1
    }
   
    filterHandler({ orderBy: orderByField, orderIn: orderInField, page: pagination.current });
    // You can access the current sorter order with sorter.order
  };

  const updateStatus = () => {
    dispatch(
      toggleActivation({
        customerId: rowData ? rowData?._id : undefined,
      })
    )
      .then(({ payload }) => {
        if (payload) {
          if (!payload?.success) {
            notificationController.error({ message: payload?.message });
          } else {
            notificationController.success({ message: payload?.message });
          }
        }
        setLoading(false);
      })
      .catch((err: any) => {
        console.log('error', err);
        setLoading(false);
      });
  };

  const redirectDetails = () => {
    const customerId = rowData?._id;
    navigate(`/customer/customer-info/${customerId}`);
  };

  const removeCustomer = () => {
    setOpen3(true);
  };

  const hideUserModal = () => {
    setRowData(null);
    setOpen1(false);
    setOpen(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);

  };
  const debounce = (func:any, timeout=300) => {
    let timer:any;
    return (...args:any) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  };

 // Update debounced search text after a delay
  const handleSearch = debounce((value:string) => {
    setDebouncedSearchText(value);
  }, 300); // Adjust the delay as needed (in milliseconds)

  // Handle input change
  const handleSearchChange = (value:string) => {
    setSearchText(value);
    handleSearch(value);
  };

  const rowClicked = (record: any) => {
    setRowData(record);
  };

  const positionItems = [
    {
      key: '1',
      label: t('common.addfund'),
      onClick: () => openAddFundModal(),
    },
    {
      key: '2',
      label: t('common.view-profile'),
      onClick: () => redirectDetails(),
    },
    
  ];

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: t('common.user-name'),
      dataIndex: 'userName',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.full-name'),
      dataIndex: 'fullName',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.mobile'),
      dataIndex: 'mobile',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.balance'),
      dataIndex: 'accountBalance',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.password'),
      dataIndex: 'password',
    },
    {
      title: t('common.master-password'),
      dataIndex: 'masterPassword',
      showSorterTooltip: false,
    },
    {
      title: t('common.createdAt'),
      dataIndex: 'createdAt',
      sorter: true,
      showSorterTooltip: false,
      render: (createdAt: string) => (
        <BaseRow gutter={[10, 10]}>
          <BaseCol>
            {createdAt &&
              <>
              {
                new Date(createdAt).toLocaleString('en-IN', { timeZone: 'UTC' })
              } 
              </>
              }
          </BaseCol>
        </BaseRow>
      ),
    },  
    {
      title: t('common.status'),
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      showSorterTooltip: false,

      render: (status: string) => (
        <BaseRow gutter={[10, 10]}>
          <BaseCol>
            {status && status == 'ACTIVE' ? (
              <Status color={'green'} text={status} />
            ) : (
              <>
                {status == 'DELETED' ? <Status color={'red'} text={status} /> : <Status color={'blue'} text={status} />}
              </>
            )}
          </BaseCol>
        </BaseRow>
      ),
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '4%',
      render: (text: string, record: any) => {
        return (
          <BaseSpace>
            <BaseDropdown menu={{ items: positionItems }} trigger={['click']}>
              <BaseButton size={'small'} onClick={(e) => rowClicked(record)} style={{marginLeft:10}}>
                <MoreOutlined />
              </BaseButton>
            </BaseDropdown>
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <>
       {rowData &&
       <>
      <AddFundFormModal open={open1} onCancel={hideUserModal} customerDetail={rowData} />
      <EditCustomerFormModal open={open} onCancel={hideUserModal} customerDetail={rowData} />
      </>
       }
       {
        isTablet && <Input
        placeholder="Search..."
        size={'small'}
        prefix={<SearchOutlined />}
        value={searchText}
        onChange={(e) => handleSearchChange(e.target.value)}
        style={{ marginBottom: 16,width:400}}
      />
       }
        {
        !isTablet && <Input
        placeholder="Search..."
        size={'small'}
        prefix={<SearchOutlined />}
        value={searchText}
        onChange={(e) => handleSearchChange(e.target.value)}
        style={{ marginBottom: 16}}
      />
       }
      <BaseTable
        columns={columns}
        dataSource={customers ? customers : []}
        pagination={{
          defaultCurrent: pagination?.currentPage,
          pageSize: 10,
          total: pagination ? pagination.totalItems : 1,
        }}
        loading={loading}
        scroll={{ x: 800 }}
        onChange={handleTableChange}
        bordered
      />
    </>
  );
};
