import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { ConfirmItemPassword } from '@app/components/profile/profileCard/profileFormNav/nav/SecuritySettings/passwordForm/ConfirmPasswordItem/ConfirmPasswordItem';
import { CurrentPasswordItem } from '@app/components/profile/profileCard/profileFormNav/nav/SecuritySettings/passwordForm/CurrentPasswordItem/CurrentPasswordItem';
import { NewPasswordItem } from '@app/components/profile/profileCard/profileFormNav/nav/SecuritySettings/passwordForm/NewPasswordItem/NewPasswordItem';
import { notificationController } from '@app/controllers/notificationController';
import * as S from './PasswordForm.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { AdminChangePassword } from '@app/interfaces/interfaces';
import { changeMasterPassword } from '@app/store/actions/adminAction';
import { useAppDispatch } from '@app/hooks/reduxHooks';
export const MasterPasswordForm: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  interface MasterPasswordFormValues {
    masterPassword?: string;
    newPassword?: string;
    confirmPassword: string;
  }
  const initialInfoValues: MasterPasswordFormValues = {
    masterPassword: '',
    newPassword: '',
    confirmPassword: '',
  };
  const onFinish = useCallback(
    (values: AdminChangePassword) => {
      const inputData = {
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
        masterPassword: values.masterPassword,
      };
      setLoading(true);
      dispatch(changeMasterPassword(inputData))
        .then(({ payload }) => {
          if (payload) {
            if (!payload?.success) {
              notificationController.error({ message: payload?.message });
            } else {
              notificationController.success({ message: payload?.message });
            }
          }
          setLoading(false);
        })
        .catch((err: any) => {
          console.log('error', err);
          // notificationController.error({ message: err.message });
          setLoading(false);
        });
    },
    [t],
  );
  return (
    <BaseButtonsForm
      name="newPassword"
      requiredMark="optional"
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      footer={
        <S.Btn loading={isLoading} type="primary" htmlType="submit">
          {t('common.confirm')}
        </S.Btn>
      }
      onFinish={onFinish}
    >
      <BaseRow gutter={{ md: 15, xl: 30 }}>
      <BaseCol span={24}>
          <BaseButtonsForm.Item>
            <BaseButtonsForm.Title>{t('common.change-master-password')}</BaseButtonsForm.Title>
          </BaseButtonsForm.Item>
        </BaseCol>

        <BaseCol xs={24} md={12} xl={24}>
          <BaseButtonsForm.Item
            name="newPassword"
            label={t('common.newPassword')}
            rules={[
              { required: true, message: t('common.requiredField') },
              { min: 8, message: t('common.password-min-rule') },
            ]}
          >
            <BaseInput />
          </BaseButtonsForm.Item>
        </BaseCol>

        <BaseCol xs={24} md={12} xl={24}>
          <BaseButtonsForm.Item
            name="confirmPassword"
            label={t('common.confirmPassword')}
            dependencies={['newPassword']}
            rules={[
              { required: true, message: t('common.requiredField') },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('common.confirmPasswordError')));
                },
              }),
            ]}
            hasFeedback
          >
            <BaseInput />
          </BaseButtonsForm.Item>
        </BaseCol>
        <BaseCol xs={24} md={12} xl={24}>
          <BaseButtonsForm.Item
            name="masterPassword"
            label={t('common.oldMasterPassword')}
            rules={[
              { required: true, message: t('common.requiredField') },
              { min: 8, message: t('common.password-min-rule') },
            ]}
          >
            <BaseInput />
          </BaseButtonsForm.Item>
        </BaseCol>
      </BaseRow>
    </BaseButtonsForm>
  );
};
