import React, { useCallback, useMemo, useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { Row, Col, Button } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { useAppSelector,useAppDispatch } from '@app/hooks/reduxHooks';
import { deleteGameNumber } from "@app/store/actions/settingAction";
import * as S from './DeleteFileForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
interface DeleteConfirmationProps {
  open: boolean;
  onCancel: () => void;
  numberId: string;
  displayNumber: string;
}

export const DeleteNumberConfirmation: React.FC<DeleteConfirmationProps> = ({ open, onCancel,  numberId, displayNumber }) => {
  const dispatch = useAppDispatch();
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
 

  const onOk = () => {
    setLoading(true);
    const inputData ={
      gameNumberId:numberId   
    }
    dispatch(deleteGameNumber(inputData)).then(({payload}) => {
      if (payload) {
        if (!payload?.success) {
          notificationController.error({ message: payload?.message });
        }
        else {
          notificationController.success({ message: payload?.message });
          onCancel()
        }
      }
      setLoading(false);
    })
    .catch((err:any) => {
      console.log("error", err)
      //  notificationController.error({ message: err.message });
      setLoading(false);
    });
    form.submit();
  
  };


 

 

  

  return (
    <BaseModal
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
         {t('common.cancel')} 
        </Button>,
        <Button key="extra" type="primary" danger onClick={onOk}>
         {t('common.delete')} 
        </Button>

      
      ]}
    >
      <BaseForm form={form} layout="vertical" name="adminForm">
      <Auth.FormTitle>{t('common.delete-number')}</Auth.FormTitle>
        <Row gutter={16}>
       <Col>
       <S.NumberDetail>
        {displayNumber && <>{t('common.game-number')}: <S.HighLighted>{displayNumber}</S.HighLighted> </>}
        </S.NumberDetail>
        <br>
        </br>
        <S.WarningText>
        {t('common.delete-number-confirmation')}
        </S.WarningText>
      
          </Col>
        </Row>
      </BaseForm>
    </BaseModal>
  );
};
