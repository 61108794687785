import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { DisplayNumberForm } from '@app/components/settings/DisplayNumber/DisplayNumberForm';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import * as S from './ProfileInfo.styles';
import { BannerUploader } from '@app/components/settings/BettingForm/BannerUploader';
import { Image } from 'antd';
import { UploadBrand } from '@app/components/settings/BettingForm/UploadBrand';
import { Slider } from '@app/components/settings/Slider/Slider';
import { getSettingList } from '@app/store/actions/settingAction';
import { Banner } from '@app/components/settings/Slider/Banner';
import { AddMinBet } from '@app/components/settings/BettingForm/AddMinBet';
import { CloseMode } from '@app/components/settings/BettingForm/CloseMode';
import { SliderUploader } from '@app/components/settings/BettingForm/SliderUploader';
import { BaseTabs, TabPane } from '@app/components/common/BaseTabs/BaseTabs';
const SettingPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [settingData, setSettingData] = useState(null);
  const [bannerData, setBannerData] = useState([]);
  const [sliderData, setSliderData] = useState();
  const { settings, isLoader, isSettingRecordUpdated, error: apiError } = useAppSelector((state) => state.setting);

  const cc = settings;
  useEffect(() => {
    dispatch(getSettingList());
  }, [isSettingRecordUpdated]);

  useEffect(() => {
    setSettingData(settings);
    setBannerData(settings?.banners);
    setSliderData(settings?.sliders);
  }, [settings]);

  return (
    <>
      <PageTitle>{t('common.settings')}</PageTitle>

      <S.Card>
        <BaseTabs defaultActiveKey="1">
          <TabPane tab={`${t('common.upload-banner')}`} key="1">
            <div style={{ paddingTop: '16px' }}>
              <BannerUploader />
              <Banner bannerData={bannerData} />
            </div>
          </TabPane>
          <TabPane tab={`${t('common.upload-slider')}`} key="2">
            <div style={{ paddingTop: '16px' }}>
              <SliderUploader />
              <Slider sliderData={sliderData} />
            </div>
          </TabPane>
          <TabPane tab={`${t('common.brand-update')}`} key="3">
            <div style={{ paddingTop: '16px' }}>
              <Image width={100} height={100} src={settingData?.logo?.link} />
              <h1>{settingData?.brandName}</h1>
              <UploadBrand  />
            </div>
          </TabPane>
          <TabPane tab={`${t('common.update-min-bet')}`} key="4">
            <div style={{ paddingTop: '16px' }}>
              <AddMinBet />
            </div>
          </TabPane>
          <TabPane tab={`${t('common.update-close')}`} key="5">
            <div style={{ paddingTop: '16px' }}>
              <CloseMode />
            </div>
          </TabPane>
          <TabPane tab={`${t('common.updateDisplayNumber')}`} key="6">
            <div style={{ paddingTop: '16px' }}>
              <DisplayNumberForm />
            </div>
          </TabPane>
        </BaseTabs>
      </S.Card>
    </>
  );
};

export default SettingPage;
