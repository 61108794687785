import { createAsyncThunk } from '@reduxjs/toolkit'
import DataService from "../service";
import { constant } from '../constant'
import { AgentRequest } from '@app/interfaces/interfaces';
const { path } = constant;

export const fetchAgentList = createAsyncThunk(
    'agent/fetchAgent',
    (arg:any, { rejectWithValue }) => DataService.get(arg,  path+'admin/agent/v1/list', rejectWithValue)
)

export const createUpdateAgent = createAsyncThunk(
    'agent/createUpdateAgent',
    (arg:AgentRequest, { rejectWithValue }) => DataService.post(arg, path+'admin/agent/v1', rejectWithValue)
)

export const deleteAgent = createAsyncThunk(
    'agent/deleteAgent',
    (arg:any, { rejectWithValue }) => DataService.delete(arg, path+`admin/agent/v1/delete/${arg?.agentId}`, rejectWithValue)
)

export const toggleActivation = createAsyncThunk(
    'agent/toggleActivation',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`admin/agent/v1/toggle-status/${arg?.agentId}`, rejectWithValue)
)

export const changeAgentPassword = createAsyncThunk(
    'agent/changeAgentPassword',
    (arg:any, { rejectWithValue }) => DataService.post(arg, path+'admin/agent/v1/change-password', rejectWithValue)
)

export const changeAgentMasterPassword = createAsyncThunk(
    'agent/changeAgentMasterPassword',
    (arg:any, { rejectWithValue }) => DataService.post(arg, path+'admin/agent/v1/change-master-password', rejectWithValue)
)

export const updateAgentFund = createAsyncThunk(
    'agent/updateAgentFund',
    (arg:any, { rejectWithValue }) => DataService.post(arg, path+'admin/agent/v1/update-fund', rejectWithValue)
)

export const getAgentByAgentId = createAsyncThunk(
    'agent/getAgentByAgentId',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`admin/agent/v1/single/${arg?.agentId}`, rejectWithValue)
)

export const getAgentFundByAgentId = createAsyncThunk(
    'agent/getAgentFundByAgentId',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`admin/agent/v1/transactions/${arg?.agentId}`, rejectWithValue)
)



export const getAgentCustomerByAgentId = createAsyncThunk(
    'agent/getAgentCustomerByAgentId',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`admin/agent/v1/customers/${arg?.agentId}`, rejectWithValue)
)