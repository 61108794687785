import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { notificationController } from '@app/controllers/notificationController';
import * as S from './DynamicForm.styles';
import { useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { createUpdateNumber } from '@app/store/actions/settingAction';
import { DisplayNumberTable } from './DisplayNumberTable';
import { DeleteFileConfirmation } from '../DeleteFileCofirmation';

export const DisplayNumberForm: React.FC = () => {
  const {
    settings,
    isLoader,
    isSettingRecordUpdated,
    error: apiError,
  } = useAppSelector((state) => state.setting);
  const [rowData, setRowData] = useState(null);
  const [numberId, setNumberId] =useState("")
  const [displayNumber, setDisplayNumber] =useState("")
  const [open, setOpen] = useState(false);
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [brandDetails, setBrandDetails] = useState({
    displayNumber: '',
    multipleTime:''
  });

  const deleteNumberFromList = async (numberId: string, dispNumber:string) => {
    setNumberId(numberId);
    setDisplayNumber(dispNumber);
    setOpen(true);
  };

  const hideDeleteFileModal = () => {
    setNumberId('');
    setDisplayNumber('');
    setOpen(false);
  };

  const onFinish = async (values = {}) => {
    setLoading(true);

    try {
      // Prepare the data in the desired format
      const gameNumbers = values.gameNumbers.map((gameNumber: any, index: number) => ({
        displayNumber: gameNumber.displayNumber,
        multipleTime: parseInt(gameNumber.multipleTime), // assuming multipleTime is a string, convert it to a number
        // _id: `id-${index}`, // generate a unique id based on index
      }));

      // Send the formatted data to the API
      const response = await dispatch(createUpdateNumber({ gameNumbers:[...gameNumbers,...settings.gameNumbers] }));

      if (response.payload?.success) {
        notificationController.success({ message: t('common.success') });
      } else {
        notificationController.error({ message: t('common.error') });
      }
    } catch (error) {
      console.error(error);
      notificationController.error({ message: t('common.error') });
    } finally {
      setLoading(false);
      setFieldsChanged(false);
    }
  };

 

  return (
    <>
    <DisplayNumberTable tableData={settings?.gameNumbers}/>
    <BaseButtonsForm
      form={form}
      name="dynamicForm"
      isFieldsChanged={isFieldsChanged}
      loading={isLoading}
      onFinish={onFinish}
      autoComplete="off"
      onFieldsChange={() => setFieldsChanged(true)}
    >
      
      <BaseButtonsForm.List name="gameNumbers">
        {(fields, { add }) => (
          <>
            {fields.map((field) => (
              <BaseRow key={field.key} wrap={false} gutter={[10, 10]} align="middle" justify="space-between">
                <BaseCol span={12}>
                  <BaseButtonsForm.Item
                    noStyle
                    // eslint-disable-next-line
                    shouldUpdate={(prevValues: any, curValues: any) =>
                      prevValues.area !== curValues.area || prevValues.gameNumbers !== curValues.gameNumbers
                    }
                  >
                    {() => (
                      <BaseButtonsForm.Item
                        {...field}
                        label={'Number'}
                        name={[field.name, 'displayNumber']}
                        fieldKey={[field.key, 'displayNumber']}
                        rules={[{ required: true, message: t('forms.dynamicFormLabels.sightError') }]}
                      >
                        <S.Wrapper>
                      <BaseInput />
                      </S.Wrapper>
                      </BaseButtonsForm.Item>
                    )}
                  </BaseButtonsForm.Item>
                </BaseCol>
                <BaseCol span={12}>
                  
                  <BaseButtonsForm.Item
                    {...field}
                    label={"Multiple Time"}
                    name={[field.name, 'multipleTime']}
                    fieldKey={[field.key, 'multipleTime']}
                    rules={[{ required: true, message: t('forms.dynamicFormLabels.priceError') }]}
                  >
                    <S.Wrapper>
                      <BaseInput />
                      <S.RemoveBtn onClick={(e) => remove(e)} />
                    </S.Wrapper>
                  </BaseButtonsForm.Item>
                </BaseCol>
              </BaseRow>
            ))}

            <BaseButtonsForm.Item>
              <BaseButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                {t('common.more')}
              </BaseButton>
            </BaseButtonsForm.Item>
          </>
        )}
      </BaseButtonsForm.List>
    </BaseButtonsForm>
    </>
  );
};
