import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel, Image } from 'antd';
import * as S from './SliderBannerCard.styles';
import { CloseCircleOutlined } from '@ant-design/icons';
import { DeleteFileConfirmation } from '../DeleteFileCofirmation';
const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return <S.SliderArrow className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
};

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return <S.SliderArrow className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
};

interface BannerProps {
  bannerData: []
}


export const Banner: React.FC<BannerProps> = ({ bannerData }) => {

  const [fileName, setFileName] =useState("")
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();



  const deleteFileFromBanner = async (fileName: string) => {
    setFileName(fileName);
    setOpen(true);
  };

  const hideDeleteFileModal = () => {
    setFileName('');
    setOpen(false);
  };

  return (
    <>
    <DeleteFileConfirmation open={open} onCancel={hideDeleteFileModal} fileName={fileName} from={'BANNER'}/>
      {bannerData && (
        <Carousel style={{ paddingTop: '20px', paddingBottom: '20px' }} arrows={true} nextArrow={<NextArrow />} prevArrow={<PrevArrow />} slidesToShow={4}>
          {bannerData.map((imgUrl, index) => (
            <div key={index} style={{ position: 'relative', display: 'inline-block', marginRight: '10px' }}>
              <Image key={index} width={200} height={200} src={imgUrl?.link} />
              <CloseCircleOutlined onClick={(e) => deleteFileFromBanner(imgUrl?.fileName)}  style={{ position: 'absolute',top:'0',color:'red',fontSize: '24px', cursor:'pointer'}} /> {/* Use the correct icon */}
            </div>
          ))}
        </Carousel>
      )}
    </>
  );
};
