import React, { useCallback, useMemo, useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { useResetFormOnCloseModal } from './useResetFormOnCloseModal';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { Row, Col, Button } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { useAppSelector,useAppDispatch } from '@app/hooks/reduxHooks';
import { deleteAgent } from "@app/store/actions/agentAction";
import { AgentChangePassword } from '@app/interfaces/interfaces';
import * as S from './NewPasswordForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
interface DeleteConfirmationProps {
  open: boolean;
  onCancel: () => void;
  agentDetail: any
}

export const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({ open, onCancel,  agentDetail }) => {
  const dispatch = useAppDispatch();
  const [form] = BaseForm.useForm();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  useResetFormOnCloseModal({
    form,
    open,
  });

  const onOk = () => {
    form.submit();
    // onCancel()
  };


  const onFinish = useCallback(
    (values: AgentChangePassword) => {
      setLoading(true);
      console.log("values",values)
      const inputData ={
        agentId: agentDetail?._id,
        
      }
     
      dispatch(deleteAgent(inputData)).then(({payload}) => {
        if (payload) {
          if (!payload?.success) {
            notificationController.error({ message: payload?.message });
          }
          else {
            notificationController.success({ message: payload?.message });
            onCancel()
          }
        }
        setLoading(false);
      })
      .catch((err:any) => {
        console.log("error", err)
         notificationController.error({ message: err.message });
        setLoading(false);
      });
    },
    [t],
  );

  interface FormField {
    name: string;
    label: string;
    type: string; // Add type attribute
    placeholder: string; // Add placeholder attribute
  }

  

  return (
    <BaseModal
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
         {t('common.cancel')} 
        </Button>,
        <Button key="extra" type="primary" danger onClick={onOk}>
         {t('common.delete')} 
        </Button>

      
      ]}
    >
      <BaseForm form={form} layout="vertical" name="adminForm"  onFinish={onFinish}>
      <Auth.FormTitle>{t('common.delete-agent')}</Auth.FormTitle>
        <Row gutter={16}>
       <Col>
        <S.UserDetail>
        {agentDetail && <>User Name: <S.HighLighted>{agentDetail.userName}</S.HighLighted> <br></br> Full Name: <S.HighLighted>{agentDetail.fullName}</S.HighLighted>  </>}
        </S.UserDetail>
        <br>
        </br>
        <S.WarningText>
        {t('common.delete-confirmation')}
        </S.WarningText>
      

          </Col>
        </Row>
      </BaseForm>
    </BaseModal>
  );
};
