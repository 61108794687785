import { createAsyncThunk } from '@reduxjs/toolkit';
import DataService from '../service';
import { constant } from '../constant';
import { SettingRequest } from '@app/interfaces/interfaces';
const { path } = constant;

export const createUploadBrand = createAsyncThunk(
  'setting/createUploadBrand',
  (arg: SettingRequest, { rejectWithValue }) =>
    DataService.post(arg, path + 'admin/account/v1/settings/update-brand', rejectWithValue),
);

export const createUpdateSliderBanner = createAsyncThunk(
  'setting/createUpdateSliderBanner',
  (arg: SettingRequest, { rejectWithValue }) =>
    DataService.post(arg, path + 'admin/account/v1/settings/update-banners', rejectWithValue),
);
export const createUpdateMinBet = createAsyncThunk(
  'setting/createUpdateMinBet',
  (arg: any, { rejectWithValue }) =>
    DataService.post(arg, path + 'admin/account/v1/settings/update-minimum-bet', rejectWithValue),
);

export const updateCloseMode = createAsyncThunk(
  'setting/updateCloseMode',
  (arg: any, { rejectWithValue }) =>
    DataService.post(arg, path + 'admin/account/v1/settings/update-close-mode', rejectWithValue),
);

export const createUpdateNumber = createAsyncThunk(
  'setting/createUpdateNumber',
  (arg: any, { rejectWithValue }) =>
    DataService.post(arg, path + 'admin/account/v1/settings/update-numbers', rejectWithValue),
);

export const getSettingList = createAsyncThunk('setting/getSettingList', (arg:any, { rejectWithValue }) =>
  DataService.get(arg, path + 'public/v1/settings', rejectWithValue),
);

export const deleteFile = createAsyncThunk(
  'setting/deleteFile',
  (arg: any, { rejectWithValue }) =>
    DataService.post(arg, path + 'admin/account/v1/settings/delete-file', rejectWithValue),
);

export const deleteGameNumber = createAsyncThunk(
  'setting/deleteGameNumber',
  (arg: any, { rejectWithValue }) =>
    DataService.post(arg, path + 'admin/account/v1/settings/delete-game-number', rejectWithValue),
);


export const updatePrivacyPolicy = createAsyncThunk(
  'setting/updatePrivacyPolicy',
  (arg: any, { rejectWithValue }) =>
    DataService.post(arg, path + 'admin/account/v1/settings/update-privacy', rejectWithValue),
);

export const updateBetRules = createAsyncThunk(
  'setting/updateBetRules',
  (arg: any, { rejectWithValue }) =>
    DataService.post(arg, path + 'admin/account/v1/settings/update-bet-rule', rejectWithValue),
);


export const updateTerms = createAsyncThunk(
  'setting/updateTerms',
  (arg: any, { rejectWithValue }) =>
    DataService.post(arg, path + 'admin/account/v1/settings/update-terms', rejectWithValue),
);
export const updateRefund = createAsyncThunk(
  'setting/updateRefund',
  (arg: any, { rejectWithValue }) =>
    DataService.post(arg, path + 'admin/account/v1/settings/update-refund', rejectWithValue),
);

export const fetchTimeTableList = createAsyncThunk('setting/fetchTimeTableList', (arg:any, { rejectWithValue }) =>
  DataService.get(arg, path + 'admin/account/v1/time-tables', rejectWithValue),
);


export const createTimeTable = createAsyncThunk(
  'agent/createTimeTable',
  (arg:any, { rejectWithValue }) => DataService.post(arg, path+'admin/account/v1/add-time-table', rejectWithValue)
)

export const updateTimeTable = createAsyncThunk(
  'agent/updateTimeTable',
  (arg:any, { rejectWithValue }) => DataService.post(arg, path+'admin/account/v1/update-time-table', rejectWithValue)
)

export const deleteBetTime = createAsyncThunk(
  'agent/deleteBetTime',
  (arg:any, { rejectWithValue }) => DataService.delete(arg, path+`admin/account/v1/delete-time-table/${arg?.timeTableId}`, rejectWithValue)
)