import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Pagination, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { Table, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useResponsive } from '@app/hooks/useResponsive';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { Status } from '@app/components/Status/Status';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseDropdown } from '@app/components/common/BaseDropdown/Dropdown';
import { MoreOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { EditCustomerFormModal } from './EditCustomerFormModal';
import { AddFundFormModal } from './AddFundFormModal';
import { ChangePassword } from './ChangePassword';
import { ChangeMasterPassword } from './ChangeMasterPassword';
import { fetchCustomerList, toggleActivation, deleteCustomer, getCustomerFundByCustomerId } from '@app/store/actions/customerAction';
import { DeleteConfirmation } from './DeleteCofirmation';


export const CustomerTransactionList: React.FC = () => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const dispatch = useAppDispatch();
  const {
    customerFundInfo,
    isLoader,
    isCustomerFundUpdated,
    error: apiError,
  } = useAppSelector((state) => state.customer);


  const [filterOption, setFilterOption] = useState({
    customerId,
    page: 1,
    search: '',
    orderBy: 'createdAt',
    orderIn: -1,
  });

  const [debouncedSearchText, setDebouncedSearchText] = useState<string | null>('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { isTablet } = useResponsive();
  useEffect(() => {
    dispatch(getCustomerFundByCustomerId(filterOption));
  }, [filterOption]);

  const filterHandler = (obj: any) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    if (debouncedSearchText || debouncedSearchText === '') {
      filterHandler({ search: debouncedSearchText, page: 1 });
    }
  }, [debouncedSearchText]);

  const handleTableChange = (pagination, filters, sorter) => {
    const orderByField = sorter?.field;
    let orderInField = null;
    if (sorter?.order === 'ascend') {
      orderInField = 1;
    } else if (sorter?.order === 'descend') {
      orderInField = -1;
    }
    filterHandler({ orderBy: orderByField, orderIn: orderInField, page: pagination.current });
    // You can access the current sorter order with sorter.order
  };

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: t('common.amount'),
      dataIndex: 'amount',
 
    },
    {
      title: t('common.transactionType'),
      dataIndex: 'transactionType',

    },
    {
      title: t('common.remark'),
      dataIndex: 'remark',
   
    },
  
    {
      title: t('common.balance'),
      dataIndex: 'accountBalance',
    },
    {
      title: t('common.updatedAt'),
      dataIndex: 'createdAt',
      sorter: true,
      showSorterTooltip: false,
      render: (createdAt: string) => (
        <BaseRow gutter={[10, 10]}>
          <BaseCol>
            {createdAt &&
              <>
              {
                new Date(createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
               
              } 
             
              </>
              }
          </BaseCol>
        </BaseRow>
      ),
    },
    
  ];

  return (
    <>
    {(customerFundInfo && customerFundInfo?.transactionList) &&
      <BaseTable
      columns={columns}
      dataSource={customerFundInfo?.transactionList ? customerFundInfo?.transactionList : []}
      pagination={{
        defaultCurrent: customerFundInfo?.pagination ? customerFundInfo?.pagination?.currentPage:1,
        pageSize: 10,
        total: customerFundInfo?.pagination ? customerFundInfo?.pagination.totalItems : 1,
      }}
      loading={loading}
      scroll={{ x: 800 }}
      onChange={handleTableChange}
      bordered
    />
    }
    
    </>
  );
};
