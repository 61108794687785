import React, { useCallback, useMemo, useState,useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useResetFormOnCloseModal } from './useResetFormOnCloseModal';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Dates } from '@app/constants/Dates';
import { notificationController } from '@app/controllers/notificationController';
import { AgentRequest } from '@app/interfaces/interfaces';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { updateTimeTable } from "@app/store/actions/settingAction";
import { useAppDispatch } from '@app/hooks/reduxHooks';

interface EditBetTimeFormModalProps {
  open: boolean;
  onCancel: () => void;
  betTimeDetail:any
}

export const EditBetTimeFormModal: React.FC<EditBetTimeFormModalProps> = ({ open, onCancel, betTimeDetail }) => {

  const [isFieldsChanged, setFieldsChanged] = useState(true);
  const [editBetTime, setEditBetTime] = useState({
    timeTableId: '',
    startHour: '',
    startMinute: '',
    duration: '',
  });
  const [isLoading, setLoading] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onFinish = useCallback(
    (values: any) => {
      let inputData = {
        startHour: parseInt(values?.startHour),
        startMinute: parseInt(values?.startMinute),
        duration: parseInt(values?.duration),
      }
      setLoading(true);
      if(betTimeDetail){
        inputData={timeTableId :betTimeDetail._id,
          ...inputData};
      }
      dispatch(updateTimeTable(inputData)).then(({payload}) => {
        if (payload) {
          if (!payload?.success) {
            notificationController.error({ message: payload?.message });
          }
          else {
            notificationController.success({ message: payload?.message });
            onCancel()
          }
        }
        setLoading(false);
        
      })
      .catch((err:any) => {
        console.log("error", err)
        //  notificationController.error({ message: err.message });
        setLoading(false);
      });
    },
    [t],
  );

  const onOk = () => {
    form.submit();

  };

  useEffect(() => {
    if(betTimeDetail){
      setEditBetTime(
        {
          startHour: betTimeDetail?.startHour,
          startMinute: betTimeDetail?.startMinute,
          duration: betTimeDetail?.duration,
          timeTableId: betTimeDetail._id,
        }
      )
    }
   
  }, [betTimeDetail]);
  

  return (
    <>
      <BaseModal title={t('common.edit-bet-time')} 
      open={open} 
      onOk={onOk} onCancel={onCancel}
       style={{ width: '80%' }}
       footer={[]}
       >
        <BaseButtonsForm
          form={form}
          name="info"
          loading={isLoading}
          initialValues={editBetTime}
          isFieldsChanged={isFieldsChanged}
          setFieldsChanged={setFieldsChanged}
          onFieldsChange={() => setFieldsChanged(true)}
          onFinish={onFinish}
        >
           <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
            <BaseCol xs={12}>
              <BaseButtonsForm.Item
                name="startHour"
                label={t('common.startHour')}
                rules={[{ required: true, message: t('common.startHour-required') }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={12}>
              <BaseButtonsForm.Item
                name="startMinute"
                label={t('common.startMinute')}
                rules={[{ required: true, message: t('common.startMinute-required') }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
          
            <BaseCol xs={12}>
              <BaseButtonsForm.Item
                name="duration"
                label={t('common.duration')}
                rules={[{ required: true, message: t('common.duration-required') }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

          </BaseRow>
        </BaseButtonsForm>
      </BaseModal>
    </>
  );
};
