import React from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const TeenPattiPage: React.FC = () => {
  return (
    <div>
      <iframe allow="microphone *" src={"https://teenpatti.bhagyalaxmishivaexchange.com/?cid=BhagyaLaxmi&up=BhagyaLaxmi:BhagyaLaxmi@123"} style={{width:"100%", height:"86vh"}}></iframe>
    </div>
  );
};

export default TeenPattiPage;