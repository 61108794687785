import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { DisplayNumberForm } from '@app/components/settings/DisplayNumber/DisplayNumberForm';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import * as S from './ProfileInfo.styles';
import { getSettingList } from '@app/store/actions/settingAction';
import { AddPrivacy } from '@app/components/settings/AddPrivacy';
import { AddRule } from '@app/components/settings/AddRule';
import { AddTerm } from '@app/components/settings/AddTerm';
import { AddRefund } from '@app/components/settings/AddRefund';
import { BaseTabs, TabPane } from '@app/components/common/BaseTabs/BaseTabs';
const PolicyPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { settings, isLoader, isSettingRecordUpdated, error: apiError } = useAppSelector((state) => state.setting);



  useEffect(() => {
    dispatch(getSettingList());
  }, [isSettingRecordUpdated]);

 

  return (
    <>
      <PageTitle>{t('common.settings')}</PageTitle>

      <S.Card>
        <BaseTabs defaultActiveKey="1">
          
          <TabPane tab={`${t('common.update-privacy')}`} key="1">
            <div style={{ paddingTop: '16px' }}>
              <AddPrivacy />
            </div>
          </TabPane>
          <TabPane tab={`${t('common.update-bet-rule')}`} key="2">
            <div style={{ paddingTop: '16px' }}>
              <AddRule />
            </div>
          </TabPane>
          <TabPane tab={`${t('common.update-terms')}`} key="3">
            <div style={{ paddingTop: '16px' }}>
              <AddTerm />
            </div>
          </TabPane>
          <TabPane tab={`${t('common.update-refund')}`} key="4">
            <div style={{ paddingTop: '16px' }}>
              <AddRefund />
            </div>
          </TabPane>
        
        </BaseTabs>
      </S.Card>
    </>
  );
};

export default PolicyPage;
