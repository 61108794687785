import React from 'react';
import { useTranslation } from 'react-i18next';
import { profileAgentNavData } from '@app/constants/profileAgentNavData';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import * as S from './ProfileNav.styles';

export const AgentProfileNav: React.FC = () => {
  const { t } = useTranslation();
  const { agentId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <S.Wrapper>
      {profileAgentNavData.map((item) => (
        <S.Btn
          key={item.id}
          icon={item.icon}
          type="text"
          color={item.color}
          onClick={() => navigate(`/agent/${item.href}/${agentId}`)}
          $isActive={`/agent/${item.href}/${agentId}` === location.pathname}
        >
          {t(item.name)}
        </S.Btn>
      ))}
    </S.Wrapper>
  );
};
