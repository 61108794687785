import React, { useEffect, useState } from 'react';
import { SmileOutlined, UserOutlined } from '@ant-design/icons';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { BaseInput } from '../../common/inputs/BaseInput/BaseInput';
import { BaseButton } from '../../common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import * as S from './ControlForm.styles';
import { notificationController } from '@app/controllers/notificationController';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { createUploadBrand } from '@app/store/actions/settingAction';
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

interface UserType {
  name: string;
}


interface BrandFormValues {
  brandName: string;
  logo: string;
}

export const UploadBrand: React.FC = ({ settingData }) => {
  const [logofile, setLogofile] = useState(null);
  const [open, setOpen] = useState(false);
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [brandDetails, setBrandDetails] = useState({
    brandName: '',
    logo: '',
  });

  const normFile = (e = { fileList: [] }) => {
    setLogofile(e?.fileList[0])
  };

  const onFinish = async (values = {}) => {
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('brandName', brandDetails.brandName);
      formData.append('logo', logofile?.originFileObj);

      await dispatch(createUploadBrand(formData)).then(({payload}) => {
        if (payload) {
          if (payload?.success) {
            notificationController.success({ message: t('common.success') });  
          }
         
        }
      })
      .catch((err) => {
        console.log("error", err)
      });

     
    } catch (error) {
      console.error(error);
      notificationController.error({ message: t('common.error') });
    } finally {
      setLoading(false);
      setFieldsChanged(false);
    }
  };

  return (
    <BaseButtonsForm.Provider
      onFormFinish={(name, { values, forms }) => {
        if (name === 'userForm') {
          const { controlForm } = forms;
          const users = controlForm.getFieldValue('users') || [];
          controlForm.setFieldsValue({ users: [...users, values] });
          setOpen(false);
        }
      }}
    >
      <BaseButtonsForm
        {...layout}
        name="controlForm"
        isFieldsChanged={isFieldsChanged}
        footer={
          <BaseButtonsForm.Item>
            <BaseButton htmlType="submit" type="primary" loading={isLoading}>
              {t('common.submit')}
            </BaseButton>
          </BaseButtonsForm.Item>
        }
        onFinish={onFinish}
        onFieldsChange={() => setFieldsChanged(true)}
        initialValues={brandDetails}
      >
        <BaseButtonsForm.Item name="upload"  valuePropName="fileList" getValueFromEvent={normFile} >
          <BaseUpload name="logo"  listType="picture">
            <BaseButton type="default" icon={<UploadOutlined />}>
              {"Choose Logo"}
            </BaseButton>
          </BaseUpload>
        </BaseButtonsForm.Item>
        <BaseButtonsForm.Item
          name="brandName"
          label={t('common.brand-name-text')}
          rules={[{ required: true, message: 'Brand Name is required' }]}
        >
          <BaseInput
            type="text"
            placeholder={t("common.enterBrandName")}
            value={brandDetails.brandName}
            onChange={(e) => setBrandDetails({ ...brandDetails, brandName: e.target.value })}
          />
        </BaseButtonsForm.Item>
      </BaseButtonsForm>
    </BaseButtonsForm.Provider>
  );
};
