import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Tag } from 'api/table.api';
import { DeleteOutlined } from '@ant-design/icons';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';

import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { DeleteNumberConfirmation} from '../DeleteNumberCofirmation';

export const DisplayNumberTable: React.FC = ({tableData}) => {
  const {
    settings,
    isLoader,
    isSettingRecordUpdated,
    error: apiError,
  } = useAppSelector((state) => state.setting);
  const [rowData, setRowData] = useState(null);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { isMounted } = useMounted();

  const rowClicked = (record: any) => {
    setRowData(record);
    setOpen(true);
  };

  const hideModal = () => {
    setRowData(null);
    setOpen(false);

  };
 


  const columns: ColumnsType<BasicTableRow> = [
  
    {
      title: t('common.displayNumber'),
      dataIndex: 'displayNumber',
    },
    {
      title: t('common.multipleTime'),
      dataIndex: 'multipleTime',
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: { name: string; key: number }) => {
        return (
          <BaseSpace>
            <BaseButton
              type="ghost"
              onClick={(e) => rowClicked(record)}
            >
              <DeleteOutlined/>
          
            </BaseButton>
           
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <>
    {rowData && <DeleteNumberConfirmation open={open} onCancel={hideModal} numberId={rowData?._id} displayNumber={rowData?.displayNumber}/>}
    <BaseTable
      columns={columns}
      dataSource={tableData}
      scroll={{ x: 300 }}
      bordered
    />
      </>
  );
};
