import { createAsyncThunk } from '@reduxjs/toolkit'
import DataService from "../service";
import { constant } from '../constant'
import { LoginRequest } from '@app/interfaces/interfaces';
const { path } = constant;



export const adminLogin = createAsyncThunk(
    'admin/adminLogin',
   async (arg: LoginRequest, { rejectWithValue }) => DataService.post(arg, path+'admin/auth/v1/login', rejectWithValue)
)

export const forgotPassword = createAsyncThunk(
    'admin/forgotPassword',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'admin/auth/v1/forgot-password', rejectWithValue)
)

export const changePassword = createAsyncThunk(
    'admin/changePassword',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'admin/account/v1/change-password', rejectWithValue)
)

export const changeMasterPassword = createAsyncThunk(
    'admin/changeMasterPassword',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'admin/account/v1/change-master-password', rejectWithValue)
)

export const resetPassword = createAsyncThunk(
    'admin/resetPassword',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'admin/auth/v1/reset-password', rejectWithValue)
)

export const updateProfile = createAsyncThunk(
    'admin/updateProfile',
    (arg:any, { rejectWithValue }) => DataService.post(arg, path+'admin/account/v1/profile', rejectWithValue)
)

export const getProfile = createAsyncThunk(
    'admin/getProfile',
    (arg, { rejectWithValue }) => DataService.get(arg, path+'admin/account/v1/profile', rejectWithValue)
)

export const getDashboard = createAsyncThunk(
    'admin/getDashboard',
    (arg, { rejectWithValue }) => DataService.get(arg, path+'admin/account/v1/dashboard', rejectWithValue)
)

export const getAgentFund = createAsyncThunk(
    'admin/getAgentFund',
    (arg:any, { rejectWithValue }) => DataService.get(arg, path+`admin/account/v1/transactions`, rejectWithValue)
)

export const activateUser = createAsyncThunk(
    'admin/activateUser',
    (arg, { rejectWithValue }) => DataService.post(arg, path+'admin/auth/v1/activate-account', rejectWithValue)
)

