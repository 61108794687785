import React, { useCallback, useMemo, useState,useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useResetFormOnCloseModal } from './useResetFormOnCloseModal';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Dates } from '@app/constants/Dates';
import { notificationController } from '@app/controllers/notificationController';
import { AgentRequest } from '@app/interfaces/interfaces';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { createUpdateAgent } from "@app/store/actions/agentAction";
import { useAppDispatch } from '@app/hooks/reduxHooks';
interface AgentFormValues {
  userName: string;
  fullName: string;
  mobile: string;
  exposerLimit: number;
  agentId:string;
  _id:string;
}
interface EditAgentFormModalProps {
  open: boolean;
  onCancel: () => void;
  agentDetail:AgentFormValues
}

export const EditAgentFormModal: React.FC<EditAgentFormModalProps> = ({ open, onCancel, agentDetail }) => {

  const [isFieldsChanged, setFieldsChanged] = useState(true);
  const [editAgent, setEditAgent] = useState({
    agentId: '',
    userName: '',
    fullName: '',
    mobile: '',
    exposerLimit: 0,
  });
  const [isLoading, setLoading] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onFinish = useCallback(
    (values: AgentRequest) => {
      let inputData=values
      setLoading(true);
      if(agentDetail){
        inputData={agentId :agentDetail._id,...inputData};
      }
      dispatch(createUpdateAgent(inputData)).then(({payload}) => {
        if (payload) {
          if (!payload?.success) {
            notificationController.error({ message: payload?.message });
          }
          else {
            notificationController.success({ message: payload?.message });
            onCancel()
          }
        }
        setLoading(false);
        
      })
      .catch((err:any) => {
        console.log("error", err)
         notificationController.error({ message: err.message });
        setLoading(false);
      });
    },
    [t],
  );

  const onOk = () => {
    form.submit();

  };

  useEffect(() => {
    if(agentDetail){
      setEditAgent(
        {
          fullName: agentDetail?.fullName,
          exposerLimit: agentDetail?.exposerLimit,
          mobile: agentDetail?.mobile,
          userName: agentDetail?.userName,
          agentId: agentDetail._id,
        }
      )
    }
   
  }, [agentDetail]);
  

  return (
    <>
      <BaseModal title={t('common.edit-agent')} 
      open={open} 
      onOk={onOk} onCancel={onCancel}
       style={{ width: '80%' }}
       footer={[]}
       >
        <BaseButtonsForm
          form={form}
          name="info"
          loading={isLoading}
          initialValues={editAgent}
          isFieldsChanged={isFieldsChanged}
          setFieldsChanged={setFieldsChanged}
          onFieldsChange={() => setFieldsChanged(true)}
          onFinish={onFinish}
        >
          <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
            <BaseCol xs={24}>
              <BaseButtonsForm.Item name="userName" label={t('common.user-name')} rules={[
                { required: true, message:t('common.username-required')},
                { min: 6, message: t('common.username-min-rule') },
                { max: 16, message: t('common.username-max-rule') },
                ]}>
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24}>
              <BaseButtonsForm.Item name="fullName" label={t('common.full-name')} rules={[{ required: true, message: t('common.fullname-required')}]}>
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24}>
              <BaseButtonsForm.Item name="mobile" label={t('common.mobile')} rules={[
                { required: true, message: t('common.mobile-required')},
                { min: 10, message:  t('common.mobile-min-rule') },
                { max: 10, message:  t('common.mobile-min-rule') },
                {
                  pattern: new RegExp(/^[0-9]+$/),
                  message: t('common.only-digits')
                },
                ]}>
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={24} >
              <BaseButtonsForm.Item name="exposerLimit" label={t('common.exposure-limit')}  rules={[{ required: true, message: t('common.exposure-required')}]}>
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
        </BaseButtonsForm>
      </BaseModal>
    </>
  );
};
