import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';

import { ColumnsType } from 'antd/es/table';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { notificationController } from 'controllers/notificationController';

import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

import { bettingUsersList } from '@app/store/actions/betAction';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';

export const BettingTableList: React.FC<any> = ({ betId }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: '',
    orderBy: 'createdAt',
    orderIn: -1,
    betId:betId,
  });
  const [customers, setCustomer] = useState<[] | null>([]);
  const [pagination, setPagination] = useState<[] | null>([]);
  const [searchText, setSearchText] = useState<string | null>('');
  const [debouncedSearchText, setDebouncedSearchText] = useState<string | null>('');
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<{ data: BasicTableRow[]; loading: boolean }>({
    data: [],
    loading: false,
  });
  const { t } = useTranslation();
  useEffect(() => {

    dispatch(bettingUsersList(filterOption)).then(({payload}) => {
      if (payload) {
        if (payload?.success) {
          setCustomer(payload?.data?.bettings)
          setPagination(payload?.data?.pagination)
        }
        else {
          notificationController.error({ message: payload?.message });         
        }
      }
      setLoading(false);
    })
    .catch((err:any) => {
      console.log("error", err)
      //  notificationController.error({ message: err.message });
      setLoading(false);
    });
  }, [filterOption]);

  const filterHandler = (obj: any) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    if (debouncedSearchText || debouncedSearchText === '') {
      filterHandler({ search: debouncedSearchText, page: 1 });
    }
  }, [debouncedSearchText]);
  const [rowData, setRowData] = useState(null);
  const handleTableChange = (pagination, filters, sorter) => {
    const orderByField = sorter?.field;
    let orderInField = null;
    if (sorter?.order === 'ascend') {
      orderInField = 1;
    } else if (sorter?.order === 'descend') {
      orderInField = -1;
    }
    filterHandler({ orderBy: orderByField, orderIn: orderInField, page: pagination.current });
    // You can access the current sorter order with sorter.order
  };

  const debounce = (func: any, timeout = 300) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };
  const redirectDetails = () => {
    const customerId = rowData?._id;

    navigate(`/customer/customer-info/${customerId}`);
  };

  const rowClicked = (record: any) => {
    setRowData(record);
  };

  const positionItems = [
    {
      key: '1',
      label: t('common.view-profile'),
      onClick: () => redirectDetails(),
    },
  ];

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: t('common.user-name'),
      dataIndex: 'userName',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.full-name'),
      dataIndex: 'fullName',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.invested-amount'),
      dataIndex: 'investedAmount',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.bet-number'),
      dataIndex: 'displayNumber',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.updatedAt'),
      dataIndex: 'createdAt',
      sorter: true,
      showSorterTooltip: false,
      render: (createdAt: string) => (
        <BaseRow gutter={[10, 10]}>
          <BaseCol>{createdAt && <>{new Date(createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</>}</BaseCol>
        </BaseRow>
      ),
    },
  ];



  return (
    <BaseTable
      columns={columns}
      dataSource={customers ? customers : []}
      pagination={{
        defaultCurrent: pagination?.currentPage,
        pageSize: 10,
        total: pagination ? pagination.totalItems : 1,
      }}
      loading={loading}
      scroll={{ x: 800 }}
      onChange={handleTableChange}
      bordered
    />
  );
};
