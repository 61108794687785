import React from 'react';
import { UserTableList } from './UserTableList';

import { useTranslation } from 'react-i18next';
import * as S from './UserTable.styles';

export const UserTables: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <S.TablesWrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent:"space-between",
          alignItems:"center",
          marginBottom:"1rem"
          
        }}
      >
        <S.TableTitle>
        { t('common.customer-list') }
        </S.TableTitle>
 
      </div>
        <S.Card id="customer-table"  padding="1.25rem 1.25rem 0">
          <UserTableList />
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};