import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doLogin } from '@app/store/slices/authSlice';
import { adminLogin } from "@app/store/actions/adminAction";
import { notificationController } from '@app/controllers/notificationController';
import { socket } from '@app/socket';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { LoginRequest } from '@app/interfaces/interfaces';

export const initValues: LoginRequest = {
  userName: '',
  password: '',
};

export const LoginForm: React.FC = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);
  const [initValues, setInitValues] = useState<{userName: string|null; password: string|null}>({
    userName: '',
    password: '',
  });
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const storedUsername = localStorage.getItem('rememberedUsername');
    const storedPassword = localStorage.getItem('rememberedPassword');
    setInitValues({
      userName: storedUsername && storedUsername?.length> 0 ? storedUsername: "",
      password: storedPassword && storedPassword?.length> 0 ? storedPassword: "",
    })
  }, []);

  const handleSubmit = (values: LoginRequest) => {
    setLoading(true);
    if (rememberMe) {
      // Save username and password in local storage (not recommended for security)
      localStorage.setItem('rememberedUsername', values.userName);
      localStorage.setItem('rememberedPassword', values.password);
    }
    const loginPayload ={
      userName: values.userName,
      password: values.password,
    }
    dispatch(adminLogin(loginPayload)).then(({payload}) => {
      if (payload) {
        if (payload?.success) {
          socket.connect();
          navigate("/")    
           
        }
       
      }
      setLoading(false);
    })
    .catch((err) => {
      console.log("error", err)
      setLoading(false);
    });

    
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
        <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription>
        <Auth.FormItem
          name="userName"
          label={t('common.user-name')}
          rules={[
            { required: true, message: t('common.requiredField') },
            { min: 6, message: t('common.username-min-rule') },
            { max: 16, message: t('common.username-max-rule') },
            
          ]}
        >
          <Auth.FormInput placeholder= {t('common.user-name')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[
            { required: true, message: t('common.requiredField') },
            { min: 8, message: t('common.password-min-rule') },
          ]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          {/* <BaseForm.Item name="rememberMe" valuePropName="checked"  noStyle>
            <Auth.FormCheckbox checked={rememberMe} onChange={(e) => console.log("rememberme",e.target.checked ) } >
          
              <S.RememberMeText>{t('login.rememberMe')}</S.RememberMeText>
            </Auth.FormCheckbox>
          </BaseForm.Item> */}
          {/* <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>
          </Link> */}
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.loginbtn')}
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
