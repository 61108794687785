import React, { useEffect, useState } from 'react';

import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseInput } from '../../common/inputs/BaseInput/BaseInput';
import { BaseButton } from '../../common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import * as S from './ControlForm.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch, useAppSelector} from '@app/hooks/reduxHooks';
import { updateCloseMode } from '@app/store/actions/settingAction';
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const CloseMode: React.FC = () => {
  const {
    settings,
    isLoader,
    isSettingRecordUpdated,
    error: apiError,
  } = useAppSelector((state) => state.setting);
  const [open, setOpen] = useState(false);
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [mode, setCloseMode] = useState(settings?.closeMode);

  const onFinish = async (values = {}) => {
    setLoading(true);

    const response = await dispatch(updateCloseMode({closeMode:mode}));
    if (response.payload?.success) {
      notificationController.success({ message: t('common.success') });
      setLoading(false);
    } else {
      notificationController.error({ message: t('common.error') });
      setLoading(false);
    }
  };

  return (
    <>
    {
      (settings && settings?.minimumBetAmount) &&  <h1>{t('common.close-mode')}:- {settings?.closeMode}</h1>
    }
   
      <BaseButtonsForm
        {...layout}
        name="controlForm"
        isFieldsChanged={isFieldsChanged}
        footer={
          <BaseButtonsForm.Item>
            <BaseButton htmlType="submit" type="primary" loading={isLoading}>
              {t('common.submit')}
            </BaseButton>
          </BaseButtonsForm.Item>
        }
        onFinish={onFinish}
        onFieldsChange={() => setFieldsChanged(true)}
      >
       <BaseButtonsForm.Item name="closeMode" >
        <BaseRadio.Group defaultValue={mode} onChange={(e) => setCloseMode(e.target.value)}>
          <BaseRadio value="Auto">Auto</BaseRadio>
          <BaseRadio value="Manual">Manual</BaseRadio>

        </BaseRadio.Group>
      </BaseButtonsForm.Item>
      </BaseButtonsForm>
    </>
  );
};
