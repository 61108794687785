import React, { useEffect, useState, createContext  } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate,useParams } from 'react-router-dom';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { AgentProfileInfo } from '@app/components/profile/profileCard/AgentInfo/AgentProfileInfo';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { AgentProfileNav } from '@app/components/profile/profileCard/AgentNav/AgentProfileNav';
import { useResponsive } from '@app/hooks/useResponsive';
import { useAppSelector,useAppDispatch } from '@app/hooks/reduxHooks';
import { BaseRow } from '../common/BaseRow/BaseRow';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { getAgentByAgentId } from '@app/store/actions/agentAction';
import { getAgentFundByAgentId } from '@app/store/actions/agentAction';

export const AgentContext = createContext()
const AgentProfileLayout: React.FC = () => {

  const [agentDetails, setAgentDetails] = useState();

  const { agentId } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAgentByAgentId({ agentId })).then(({ payload }) => {
      if (payload?.success) {
        setAgentDetails(payload.data);
      }
    });
   

  }, [agentId]);


  const { t } = useTranslation();
  const { isTablet: isTabletOrHigher, mobileOnly } = useResponsive();
  const location = useLocation();
  const navigate = useNavigate();

  const { isTablet } = useResponsive();

  const isTitleShown = isTabletOrHigher || (mobileOnly && location.pathname === '/agents');
  const isMenuShown = isTabletOrHigher || (mobileOnly && location.pathname !== '/agents');

  useEffect(() => {
    isTablet && location.pathname === '/agent' && navigate('agent-info');
  }, [isTablet, location.pathname, navigate]);

  return (
    <AgentContext.Provider value={agentDetails}>
      <PageTitle>{t('profile.title')}</PageTitle>
      {!isTitleShown && (
        <div style={{display:'flex',flexWrap:'wrap'}}>
           <Btn  type="text" onClick={() => navigate(`/agent/agent-info/${agentId}`)}>
          {t('profile.nav.personalInfo.title')}
        </Btn>
        <Btn  type="text" onClick={() => navigate(`/agent/agent-fund-info/${agentId}`)}>
          {t('common.transactionInfo')}
        </Btn>
        <Btn  type="text" onClick={() => navigate(`/agent/agent-customer-info/${agentId}`)}>
          {t('common.agentCustomerInfo')}
        </Btn>

        </div>
       
      )}

      <BaseRow gutter={[30, 30]}>
        {isTitleShown && (
          <BaseCol xs={24} md={24} xl={8}>
            <ProfileCard>
              <BaseRow gutter={[30, 30]}>
                <BaseCol xs={24} md={12} xl={24}>
                  <AgentProfileInfo />
                </BaseCol>

                <BaseCol xs={24} md={12} xl={24}>
                  <AgentProfileNav />
                </BaseCol>
              </BaseRow>
            </ProfileCard>
          </BaseCol>
        )}

        {isMenuShown && (
          <BaseCol xs={24} md={24} xl={16}>
            <Outlet />
          </BaseCol>
        )}
      </BaseRow>
    </AgentContext.Provider>
  );
};

const ProfileCard = styled(BaseCard)`
  height: unset;
`;


const Btn = styled(BaseButton)`
  font-size: .8rem;
  margin: .5rem;
  font-weight: 600;
  padding: 4px;
  height: unset;
 
  background:var(--background-color);
`;

export default AgentProfileLayout;
