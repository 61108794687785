import React from 'react';
import {
  DashboardOutlined,
  UserOutlined,
  SmileOutlined,
  SettingOutlined,
  BoldOutlined,
  RocketOutlined,
  LaptopOutlined,
  ReadOutlined,
  ControlOutlined,
  PayCircleOutlined
} from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.nft-dashboard',
    key: 'nft-dashboard',
    url: '/',
    icon: <DashboardOutlined />,
  },
  {
    title: 'common.manage-agent',
    key: 'manage-agent',
    url: '/agents',
    icon: <SmileOutlined />,
  },
  {
    title: 'common.manage-customer',
    key: 'manage-customer',
    url: '/users',
    icon: <UserOutlined />,
  },
  {
    title: 'common.manage-bot',
    key: 'manage-bot',
    url: '/bot-player',
    icon: <UserOutlined />,
  },
  {
    title: 'common.live-betting',
    key: 'live-betting',
    url: '/bettings',
    icon: <LaptopOutlined />,
  },
  {
    title: 'common.manage-bet',
    key: 'manage-bet',
    url: '/bets',
    icon: <BoldOutlined />,
  },
  {
    title: 'common.transaction-history',
    key: 'history',
    url: '/payments',
    icon: <PayCircleOutlined />,
  },
  {
    title: 'common.settings',
    key: 'settings',
    url: '/settings',
    icon: <SettingOutlined />,
  },
  {
    title: 'common.policy',
    key: 'policy',
    url: '/policy',
    icon: <ReadOutlined />,
  },
  {
    title: 'common.betting-time-table',
    key: 'timetable',
    url: '/time-table',
    icon: <ControlOutlined />,
  },
  {
    title: 'common.betting-teen-patti',
    key: 'teen-patti',
    url: '/teen-patti',
    icon: <ControlOutlined />,
  },
  {
    title: 'common.betting-dragon-tiger',
    key: 'dragon-tiger',
    url: '/dragon-tiger',
    icon: <ControlOutlined />,
  },
  {
    title: 'common.betting-andar-bhaar',
    key: 'andar-bhaar',
    url: '/andar-bhaar',
    icon: <ControlOutlined />,
  },
  {
    title: 'common.betting-seven-up-down',
    key: 'seven-up-down',
    url: '/seven-up-down',
    icon: <ControlOutlined />,
  },
];
