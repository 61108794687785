import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { useResetFormOnCloseModal } from './useResetFormOnCloseModal';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Dates } from '@app/constants/Dates';
import { notificationController } from '@app/controllers/notificationController';
import { CustomerRequest } from '@app/interfaces/interfaces';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { createUpdateBot, createUpdateCustomer } from '@app/store/actions/customerAction';
import { useAppDispatch } from '@app/hooks/reduxHooks';
interface CustomerFormValues {
  userName?: string | undefined;
  fullName: string;
  mobile: string;
  exposerLimit: number;
  password: string;
  confirmPassword: string;
  masterPassword: string;
  confirmMasterPassword: string;
}

const initialInfoValues: CustomerFormValues = {
  userName: '',
  fullName: '',
  mobile: '',
  exposerLimit: 0,
  password: '',
  confirmPassword: '',
  masterPassword: '',
  confirmMasterPassword: '',
};
interface AddCustomerFormModalProps {
  open: boolean;
  onCancel: () => void;
}

export const AddCustomerFormModal: React.FC<AddCustomerFormModalProps> = ({ open, onCancel }) => {
  const [isFieldsChanged, setFieldsChanged] = useState(true);
  const [editCustomer, setEditCustomer] = useState({
    userName: '',
    fullName: '',
    mobile: '',
    password: '',
    masterPassword: '',
  });
  const [isLoading, setLoading] = useState(false);
  const [form] = BaseButtonsForm.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useResetFormOnCloseModal({
    form,
    open,
  });

  const onFinish = useCallback(
    (values: CustomerRequest) => {
      const inputData = {
        fullName: values.fullName,
        userName: values.userName,
        mobile: values.mobile,
        password: values.password,
        masterPassword: values.masterPassword,
      };
      setLoading(true);
      dispatch(createUpdateBot(inputData))
        .then(({ payload }) => {
          if (payload) {
            if (!payload?.success) {
              notificationController.error({ message: payload?.message });
            } else {
              notificationController.success({ message: payload?.message });
              onCancel()
            }
          }
          setLoading(false);
        })
        .catch((err: any) => {
          console.log('error', err);
          setLoading(false);
        });
    },
    [t],
  );

  const onOk = () => {
    form.submit();
  };

  return (
    <>
      <BaseModal
        title={t('common.add-new-bot')}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        style={{ width: '80%' }}
        footer={[]}
      >
        <BaseButtonsForm
          form={form}
          name="info"
          loading={isLoading}
          initialValues={editCustomer}
          isFieldsChanged={isFieldsChanged}
          setFieldsChanged={setFieldsChanged}
          onFieldsChange={() => setFieldsChanged(true)}
          onFinish={onFinish}
        >
          <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
            <BaseCol xs={12}>
              <BaseButtonsForm.Item
                name="fullName"
                label={t('common.full-name')}
                rules={[{ required: true, message: t('common.fullname-required') }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={12}>
              <BaseButtonsForm.Item
                name="mobile"
                label={t('common.mobile')}
                rules={[
                  { required: true, message: t('common.mobile-required') },
                  { min: 10, message: t('common.mobile-min-rule') },
                  { max: 10, message: t('common.mobile-min-rule') },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: t('common.only-digits'),
                  },
                ]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={12}>
              <BaseButtonsForm.Item
                name="userName"
                label={t('common.user-name')}
                rules={[
                  { required: true, message: t('common.username-required') },
                  { min: 6, message: t('common.username-min-rule') },
                  { max: 16, message: t('common.username-max-rule') },
                ]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={12}>
              <BaseButtonsForm.Item
                name="password"
                label={t('common.password')}
                rules={[
                  { required: true, message: t('common.requiredField') },
                  { min: 8, message: t('common.password-min-rule') },
                ]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol xs={12}>
              <BaseButtonsForm.Item
                name="masterPassword"
                label={t('common.masterPassword')}
                rules={[
                  { required: true, message: t('common.master-password-required') },
                  { min: 8, message: t('common.password-min-rule') },
                ]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
        </BaseButtonsForm>
      </BaseModal>
    </>
  );
};
