import React, { useEffect, useState, useCallback } from 'react';
import { BasicTableRow, getBasicTableData, Pagination, Tag } from 'api/table.api';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { Table, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useResponsive } from '@app/hooks/useResponsive';
import { defineColorByPriority } from '@app/utils/utils';
import { notificationController } from 'controllers/notificationController';
import { useMounted } from '@app/hooks/useMounted';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseDropdown } from '@app/components/common/BaseDropdown/Dropdown';
import { MoreOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { EditAgentFormModal } from './EditAgentFormModal';
import { AddFundFormModal } from './AddFundFormModal';
import { ChangePassword } from './ChangePassword';
import { ChangeMasterPassword } from './ChangeMasterPassword';
import { fetchAgentList, toggleActivation, deleteAgent } from '@app/store/actions/agentAction';
import { DeleteConfirmation } from './DeleteCofirmation';
import { getAgentCustomerByAgentId } from '@app/store/actions/agentAction';
import { Status } from '@app/components/Status/Status';

export const AgentCustomerList: React.FC = () => {
  const navigate = useNavigate();
  const { agentId } = useParams();
  const dispatch = useAppDispatch();
  const {
    agentFundInfo,
    isLoader,
    isAgentFundUpdated,
    error: apiError,
  } = useAppSelector((state) => state.agent);


  const [filterOption, setFilterOption] = useState({
    agentId,
    page: 1,
    search: '',
    orderBy: 'createdAt',
    orderIn: -1,
  });

  const [debouncedSearchText, setDebouncedSearchText] = useState<string | null>('');
  const [agentCustomerInfo, setAgentCustomerInfo] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { isTablet } = useResponsive();
  useEffect(() => {
    dispatch(getAgentCustomerByAgentId(filterOption)).then(({payload}) => {
      if (payload) {
        if (payload?.success) {
          setAgentCustomerInfo(payload?.data?.customers)
          setPagination(payload?.data?.pagination)
        }
        else {
          notificationController.error({ message: payload?.message });
          
        }
      }
      setLoading(false);
    })
    .catch((err:any) => {
      console.log("error", err)
      //  notificationController.error({ message: err.message });
      setLoading(false);
    });
  }, [filterOption]);

  const filterHandler = (obj: any) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    if (debouncedSearchText || debouncedSearchText === '') {
      filterHandler({ search: debouncedSearchText, page: 1 });
    }
  }, [debouncedSearchText]);

  const handleTableChange = (pagination, filters, sorter) => {

    const orderByField = sorter?.field;
    let orderInField = null;
    if (sorter?.order === 'ascend') {
      orderInField = 1;
    } else if (sorter?.order === 'descend') {
      orderInField = -1;
    }

    filterHandler({ orderBy: orderByField, orderIn: orderInField, page: pagination.current });
    // You can access the current sorter order with sorter.order
  };

  const columns: ColumnsType<BasicTableRow> = [
    {
      title: t('common.user-name'),
      dataIndex: 'userName',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.full-name'),
      dataIndex: 'fullName',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.mobile'),
      dataIndex: 'mobile',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.exposure-limit'),
      dataIndex: 'exposerLimit',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.balance'),
      dataIndex: 'accountBalance',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('common.createdAt'),
      dataIndex: 'createdAt',
      sorter: true,
      showSorterTooltip: false,
      render: (createdAt: string) => (
        <BaseRow gutter={[10, 10]}>
          <BaseCol>{createdAt && <>{new Date(createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}</>}</BaseCol>
        </BaseRow>
      ),
    },

    {
      title: t('common.status'),
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      showSorterTooltip: false,

      render: (status: string) => (
        <BaseRow gutter={[10, 10]}>
          <BaseCol>
            {status && status == 'ACTIVE' ? (
              <Status color={'green'} text={status} />
            ) : (
              <>
                {status == 'DELETED' ? <Status color={'red'} text={status} /> : <Status color={'blue'} text={status} />}
              </>
            )}
          </BaseCol>
        </BaseRow>
      ),
    },
   
  ];

  return (
    <>
    {agentCustomerInfo  &&
      <BaseTable
      columns={columns}
      dataSource={agentCustomerInfo? agentCustomerInfo : []}
      pagination={{
        defaultCurrent: pagination ? pagination?.currentPage:1,
        pageSize: 10,
        total: pagination ? pagination.totalItems : 1,
      }}
      loading={loading}
      scroll={{ x: 800 }}
      onChange={handleTableChange}
      bordered
    />
    }
    
    </>
  );
};
