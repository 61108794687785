import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionList } from './TransactionList';
import * as S from './PaymentTable.styles';

export const  PaymentTables: FC = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation(); 

 

  return (
    <>
 
    <S.TablesWrapper>
    <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent:"space-between",
          alignItems:"center",
          marginBottom:"1rem"
          
        }}
      >
        <S.TableTitle>
        { t('common.transaction-list') }
        </S.TableTitle>
  
      </div>
        <S.Card id="transaction-table" padding="1.25rem 1.25rem 0">
        
          <TransactionList />
        </S.Card>
      </S.TablesWrapper>

    </>
  );
};
