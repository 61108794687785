import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { persistUser, readUser } from '@app/services/localStorage.service';
import {
  fetchBetList,
  getBetByBetId,
  fetchCurrentBet
} from '../actions/betAction';

const initialState: any = {
  bets: null,
  error: null,
  isLoader: false,
  isBetRecordUpdated: false,
  pagination: null,
  currentBet:null,
  betById:null,
  showWinButton:false,
};

export const betSlice = createSlice({
  name: 'bet',
  initialState,
  reducers: {
    setCurrentBet: (state, { payload }) => {
      state.currentBet = payload;
      state.isLoader = false;  
      state.isBetRecordUpdated =true;
    },
    setLiveBet: (state, { payload }) => {
      state.currentBet = payload;
      state.isLoader = false;  
      state.isBetRecordUpdated =true;
    },
    showWin: (state) => {
      state.showWinButton = true;
    },
    hideWin: (state) => {
      state.showWinButton = false;
    },
    betReset: (state) => {
      state.error = null;
    },
    resetPopup: (state) => {
      state.isLoader = false;
      state.isBetUpdated = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
  
    // fetch single bet //
    builder.addCase(getBetByBetId.pending, (state, action) => {
      state.error = null;
      state.isLoader = true;
      state.isBetRecordUpdated =false;
      state.betById = null;
    });
    builder.addCase(getBetByBetId.fulfilled, (state, { payload }) => {
      state.error = null;
      state.betById = payload?.data;
      state.isLoader = false;
    });

    builder.addCase(getBetByBetId.rejected, (state, { payload }: any) => {
      state.error = payload?.message;
      state.isLoader = false;
      
    });
 
    builder.addCase(fetchBetList.pending, (state, action) => {
      state.error = null;
      state.isLoader = true;
    });
    builder.addCase(fetchBetList.fulfilled, (state, { payload }) => {
      state.error = null;
      return {
        ...state,
        ...payload?.data,
        isLoader: false,
      };
    });
    builder.addCase(fetchBetList.rejected, (state, { payload }: any) => {
      state.error = payload?.message;
      state.isLoader = false;
    });
    // end fetch Bets //


    builder.addCase(fetchCurrentBet.pending, (state, action) => {
      state.error = null;
      state.isLoader = true;
      state.isBetRecordUpdated =false;
    });
    builder.addCase(fetchCurrentBet.fulfilled, (state, { payload }) => {
      state.error = null;
      state.currentBet = payload?.data;
      state.isLoader = false;  
      state.isBetRecordUpdated =true;
    });
    builder.addCase(fetchCurrentBet.rejected, (state, { payload }: any) => {
    
      state.error = payload?.message;
      state.isLoader = false;
    });

    
  },
});
// Action creators are generated for each case reducer function
export const {showWin, hideWin, setCurrentBet} = betSlice.actions;

export default betSlice.reducer;
