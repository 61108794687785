import React, { useEffect, useState, useCallback } from 'react';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { useTranslation } from 'react-i18next';
import { socket } from '@app/socket';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { EndGameConfirmation} from './EndGameCofirmation';
interface GameNumberType{
  displayNumber:string,
  _id:string,
  multipleTime:string|number
}

export const NumberWiseBetTable: React.FC = () => {
 
  const {
    currentBet,
    error: apiError,
    showWinButton,
  } = useAppSelector((state) => state.bet);

    const [open, setOpen] = useState(false);
    const [gameNumber, setGameNumber] = useState<GameNumberType | null>(null);
    const [rowData, setRowData] = useState(null);
  const [filterOption, setFilterOption] = useState({
    page: 1,
    search: '',
    orderBy: 'createdAt',
    orderIn: -1,
  });
  const [searchText, setSearchText] = useState<string | null>('');
  const [debouncedSearchText, setDebouncedSearchText] = useState<string | null>('');
  const [loading, setLoading] = useState(false);
  const [highestprofit, setHighestProfit] = useState('');
  const [tableData, setTableData] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
      socket.on('liveBetResponse', (data) => {   
          setTableData(data);      
      });
    }
  , [ currentBet?.status, socket]);


  const filterHandler = (obj: any) => {
    setFilterOption({
      ...filterOption,
      ...obj,
    });
  };

  useEffect(() => {
    if (debouncedSearchText || debouncedSearchText === '') {
      filterHandler({ search: debouncedSearchText, page: 1 });
    }
  }, [debouncedSearchText]);


  
 

  const rowClicked = (record: any) => {
    const num = record?.displayNumber;
    const objArr = currentBet?.gameNumbers;
    const index = objArr.findIndex((x) => x?.displayNumber == num);
    setGameNumber(currentBet?.gameNumbers[index]);
    setRowData(record);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: t('common.bet-number'),
      dataIndex: 'displayNumber',
    },
    {
      title: t('common.invested-amount'),
      dataIndex: 'sumInvestedAmount',
    },
    {
      title: t('common.total-amount'),
      dataIndex: 'sumTotalAmount',
    },
    {
      title: t('common.profit'),
      dataIndex: 'profit',
      render: (profit: number, record: any) => {
         const max = Math.max.apply(null, tableData.map(item => item.profit));
     
        return (  <>
        {
          profit &&  profit==max ? <span className="blink">{profit}</span>:<>{profit<0? <span className="loss">{profit}</span>:<span >{profit}</span>}</>
        }            
        
        </>
    
        );
      },
    },
     
  ];

  const extendedColumns = [
    {
      title: t('common.bet-number'),
      dataIndex: 'displayNumber',
    },
    {
      title: t('common.invested-amount'),
      dataIndex: 'sumInvestedAmount',
    },
    {
      title: t('common.total-amount'),
      dataIndex: 'sumTotalAmount',
    },
    {
      title: t('common.profit'),
      dataIndex: 'profit',
      render: (profit: string, record: any) => {
         const max = Math.max.apply(null, tableData.map(item => item.profit));
    
        return (  <>
          {
            profit &&  profit==max ? <span className="blink">{profit}</span>:<>{profit<0? <span className="loss">{profit}</span>:<span >{profit}</span>}</>
          }      
        
        </>
    
        );
      },
    },
    {
      title: t('tables.actions'),
      dataIndex: 'actions',
      width: '4%',
      render: (text: string, record: any) => {
        return (
          
              <BaseButton type='primary' size={'small'} onClick={(e) => rowClicked(record)} style={{marginLeft:10}}>
                Win
              </BaseButton>
        );
      },
    }
     
  ];

  return (
<>
 {currentBet && <EndGameConfirmation open={open} onCancel={hideModal} betId={currentBet?._id} gameNumber={gameNumber} />}

    <BaseTable
      columns={(currentBet?.closeMode=='Manual' && showWinButton) ? extendedColumns: columns}
      dataSource={tableData}
     
      loading={loading}
      scroll={{ x: 800 }}
      bordered
    />
    </>
  );
};
