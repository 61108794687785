import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { AgentState } from '@app/interfaces/interfaces';
import { persistUser, readUser } from '@app/services/localStorage.service';
import {
  fetchAgentList,
  createUpdateAgent,
  toggleActivation,
  deleteAgent,
  changeAgentPassword,
  changeAgentMasterPassword,
  updateAgentFund,
  getAgentByAgentId,
  getAgentFundByAgentId,
} from '../actions/agentAction';

const initialState: AgentState = {
  agents: null,
  agentPersonalInfo: null,
  agentFundInfo: null,
  error: null,
  sortBy: null,
  isLoader: false,
  isRecordAdded: false,
  isAgentRecordUpdated: false,
  isAgentFundUpdated: false,
  isRecordUpdated: false,
  pagination: null,
};

export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    agentReset: (state) => {
      state.error = null;
    },
    resetPopup: (state) => {
      state.isLoader = false;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isAgentFundUpdated = false;
      state.isAgentRecordUpdated = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
     // fetch fund of agent //
     builder.addCase(getAgentFundByAgentId.pending, (state, action) => {
      state.error = null;
      state.isLoader = true;
      state.isAgentFundUpdated =false;
    });
    builder.addCase(getAgentFundByAgentId.fulfilled, (state, { payload }) => {
      state.error = null;
      state.agentFundInfo= payload?.data;
      state.isAgentFundUpdated =true;
      state.isLoader = false;
    });

    builder.addCase(getAgentFundByAgentId.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isLoader = false;
     
    });


  
    // end fetch fund of agent //

    // fetch single agent //
    builder.addCase(getAgentByAgentId.pending, (state, action) => {
      state.error = null;
      state.isLoader = true;
      state.isAgentRecordUpdated =false;
    });
    builder.addCase(getAgentByAgentId.fulfilled, (state, { payload }) => {
      state.error = null;
      state.agentPersonalInfo= payload?.data;
      state.isAgentRecordUpdated =true;
      state.isLoader = false;
    });

    builder.addCase(getAgentByAgentId.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isLoader = false;
      
    });
    // fetch single agent //


    // fetch agents //
    builder.addCase(fetchAgentList.pending, (state, action) => {
      state.error = null;
      state.isLoader = true;
    });
    builder.addCase(fetchAgentList.fulfilled, (state, { payload }) => {
      state.error = null;
      return {
        ...state,
        ...payload?.data,
        isLoader: false,
      };
    });
    builder.addCase(fetchAgentList.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isLoader = false;
    });
    // end fetch agents //

    // createUpdateAgent //
    builder.addCase(createUpdateAgent.pending, (state, action) => {
      state.error = null;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(createUpdateAgent.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(createUpdateAgent.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isRecordUpdated = false;
      state.isLoader = false;
    });
    // end createUpdateAgent //

    // toggleActivation //
    builder.addCase(toggleActivation.pending, (state, action) => {
      state.error = null;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(toggleActivation.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(toggleActivation.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isRecordUpdated = false;
      state.isLoader = false;
    });
    // end toggleActivation //

    // deleteAgent //
    builder.addCase(deleteAgent.pending, (state, action) => {
      state.error = null;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(deleteAgent.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(deleteAgent.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isRecordUpdated = false;
      state.isLoader = false;
    });
    // end deleteAgent //

    // changeAgentPassword //
    builder.addCase(changeAgentPassword.pending, (state, action) => {
      state.error = null;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(changeAgentPassword.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(changeAgentPassword.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isRecordUpdated = false;
      state.isLoader = false;
    });
    // end changeAgentPassword //

    // changeAgentMasterPassword //
    builder.addCase(changeAgentMasterPassword.pending, (state, action) => {
      state.error = null;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(changeAgentMasterPassword.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(changeAgentMasterPassword.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isRecordUpdated = false;
      state.isLoader = false;
    });
    // end changeAgentMasterPassword //

    // updateAgentFund //
    builder.addCase(updateAgentFund.pending, (state, action) => {
      state.error = null;
      state.isRecordAdded = false;
      state.isRecordUpdated = false;
      state.isLoader = true;
    });
    builder.addCase(updateAgentFund.fulfilled, (state, { payload }) => {
      state.error = null;
      state.isRecordUpdated = true;
      state.isLoader = false;
    });
    builder.addCase(updateAgentFund.rejected, (state, { payload }: any) => {
      state.error = payload.message;
      state.isRecordUpdated = false;
      state.isLoader = false;
    });
    // end updateAgentFund //
  },
});
// Action creators are generated for each case reducer function
export const {} = agentSlice.actions;

export default agentSlice.reducer;
