import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserModel } from '@app/domain/UserModel';
import * as S from './ProfileInfo.styles';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import profileImage from '@app/assets/images/profile.png';
import { useAppSelector, useAppDispatch } from '@app/hooks/reduxHooks';
import { AgentContext } from '../../AgentProfileLayout';

export const AgentProfileInfo: React.FC<ChangePasswordProps> = () => {
  const agentDetails = useContext(AgentContext);
  const [fullness, setFullness] = useState(90);
  const { t } = useTranslation();
  useEffect(() => {
    if (agentDetails?.fullName) {
      setFullness(100);
    }
  }, [agentDetails]);
  return agentDetails ? (
    <S.Wrapper>
      <S.ImgWrapper>
        <BaseAvatar shape="circle" src={profileImage} alt="Profile" />
      </S.ImgWrapper>
      <S.Title>{agentDetails?.fullName}</S.Title>
      <S.Subtitle>{agentDetails?.userName}</S.Subtitle>
      <S.FullnessWrapper>
        <S.FullnessLine width={fullness}>{fullness}%</S.FullnessLine>
      </S.FullnessWrapper>
    </S.Wrapper>
  ) : null;
};
